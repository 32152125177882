import { useEffect } from 'react';

import { GA4_KEY } from '../utils/constants';
import { sendGTMEvent } from '../utils/analytics';

const SDK_AppStarted = (
  setIsAppStarted,
  addEventListener,
  removeEventListener,
) => {
  useEffect(() => {
    const handleAppStarted = async (message) => {
      setIsAppStarted(true);

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = message;

      const scripts = tempDiv.getElementsByTagName('script');
      const noscripts = tempDiv.getElementsByTagName('noscript');

      for (const originalScript of scripts) {
        const script = document.createElement('script');
        script.type = originalScript.type;
        if (originalScript.src) script.src = originalScript.src;
        if (originalScript.innerHTML)
          script.innerHTML = originalScript.innerHTML;
        document.head.appendChild(script);
      }

      for (const noscript of noscripts) {
        document.body.appendChild(noscript.cloneNode(true));
      }

      sendGTMEvent(GA4_KEY.unity_added_script);
    };
    addEventListener('AppStarted', handleAppStarted);
    return () => removeEventListener('AppStarted', handleAppStarted);
  }, [addEventListener, removeEventListener]);
};

export default SDK_AppStarted;
