import { useEffect } from 'react';
import GAMES_CONFIG from '../GamesConfig';

export const useOrientationLock = (appId, telegram, isTelegram) => {
  useEffect(() => {
    const getScreenOrientation = () => {
      // Используем window.screen вместо screen, чтобы явно указать, что мы обращаемся к глобальному объекту
      const screenOrientation =
        window.screen.orientation ||
        window.screen.mozOrientation ||
        window.screen.msOrientation;

      if (!screenOrientation) {
        console.log('Screen Orientation API не поддерживается');
        return null;
      }

      return screenOrientation;
    };

    const lockOrientation = () => {
      if (
        isTelegram &&
        GAMES_CONFIG[appId].orientation &&
        +telegram?.version >= 8
      ) {
        const screenOrientation = getScreenOrientation();

        if (!screenOrientation) return;

        if (
          screenOrientation.type ===
          `${GAMES_CONFIG[appId].orientation}-primary`
        ) {
          telegram.lockOrientation();
        }
      }
    };

    lockOrientation();

    window.addEventListener('orientationchange', lockOrientation);

    return () => {
      window.removeEventListener('orientationchange', lockOrientation);
    };
  }, [appId, telegram, isTelegram]); // Добавил isTelegram в массив зависимостей
};
