// import { useEffect, useState } from 'react';

// import { getTAppsTasks } from '../api/tapps';

// import { DEFAULT_USER } from '../utils/constants';

// const useLoadTappadsTasks = (
//   isAppStarted,
//   sendMessage,
//   addEventListener,
//   removeEventListener
// ) => {
//   const [tappsTasks, setTappsTasks] = useState(null);
//   const TappAdsPubSdk = window.TappAdsPubSdk;

//   useEffect(() => {
//     if (!!isAppStarted && !!tappsTasks) {
//       sendMessage('TeleLauncherSDK', 'OnTappadsTasksGet', tappsTasks);
//     }
//   }, [isAppStarted, tappsTasks, sendMessage, TappAdsPubSdk]);

//   useEffect(() => {
//     const handleLoadTappadsTasks = async () => {
//       TappAdsPubSdk.init('973314c8-d009-4432-9222-9b1b4e24ce6e', {
//         debug: true,
//       })
//         .then(() => {
//           console.log('TappAdsPubSdk initialized');
//           TappAdsPubSdk.getFeed()
//             .then((feed) => {
//               console.log('Feed:', feed);
//               setTappsTasks(JSON.stringify(feed));
//               TappAdsPubSdk.getTaskStatus(feed.map((task) => task.id))
//                 .then((res) => {
//                   console.log('statuses:', res);
//                 })
//                 .catch((err) => {
//                   console.error('Error get statuses:', err);
//                 });
//             })
//             .catch((err) => {
//               console.error('Feed error:', err);
//             });
//         })
//         .catch((err) => {
//           console.error('TappAdsPubSdk initialization error:', err);
//         });

// const tapps = await getTAppsTasks(
//   window.Telegram.WebApp.initDataUnsafe.user.id || DEFAULT_USER.id
// );
// setTappsTasks(JSON.stringify(tapps));
// };

//     addEventListener('LoadTappadsTasks', handleLoadTappadsTasks);
//     return () =>
//       removeEventListener('LoadTappadsTasks', handleLoadTappadsTasks);
//   }, [addEventListener, removeEventListener]);
// };

// export default useLoadTappadsTasks;

import { useEffect, useState, useCallback } from 'react';

import { useUnity } from '../contexts/UnityContext';

const useLoadTappadsTasks = () => {
  const { isAppStarted, sendMessage, addEventListener, removeEventListener } =
    useUnity();
  const [tappsTasks, setTappsTasks] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize SDK
  const initializeSDK = useCallback(async () => {
    if (isInitialized) return;

    try {
      await window.TappAdsPubSdk.init('973314c8-d009-4432-9222-9b1b4e24ce6e', {
        debug: true,
      });
      setIsInitialized(true);
      console.log('TappAdsPubSdk initialized');
    } catch (err) {
      console.error('TappAdsPubSdk initialization error:', err);
      throw err;
    }
  }, [isInitialized]);

  // Load tasks
  const loadTasks = useCallback(async () => {
    try {
      await initializeSDK();

      const feed = await window.TappAdsPubSdk.getFeed();
      console.log('Feed:', feed);

      const taskIds = feed.map((task) => task.id);
      const statuses = await window.TappAdsPubSdk.getTaskStatus(taskIds);
      const newTasks = feed.filter((task) => statuses[task.id] === 'new');
      console.log(newTasks);

      // // Combine feed with statuses
      // const tasksWithStatus = feed.map((task) => ({
      //   ...task,
      //   status: statuses[task.id] || 'unknown',
      // }));
      return newTasks;
    } catch (err) {
      console.error('Failed to load tasks:', err);
      throw err;
    }
  }, [initializeSDK]);

  // Send tasks to TeleLauncher when loaded
  useEffect(() => {
    if (isAppStarted && tappsTasks) {
      sendMessage(
        'TeleLauncherSDK',
        'OnTappadsTasksGet',
        JSON.stringify(tappsTasks)
      );
    }
  }, [isAppStarted, tappsTasks, sendMessage]);

  // Set up event listener
  useEffect(() => {
    let isMounted = true;

    const handleLoadTappadsTasks = async () => {
      try {
        const tasks = await loadTasks();
        if (isMounted) {
          setTappsTasks(tasks);
        }
      } catch (error) {
        if (isMounted) {
          // Handle error appropriately
          console.error('Failed to load Tappads tasks:', error);
        }
      }
    };

    addEventListener('LoadTappadsTasks', handleLoadTappadsTasks);

    return () => {
      isMounted = false;
      removeEventListener('LoadTappadsTasks', handleLoadTappadsTasks);
    };
  }, [addEventListener, removeEventListener, loadTasks]);

  return tappsTasks;
};

export default useLoadTappadsTasks;
