import { useEffect } from 'react';

import { useUnity } from '../contexts/UnityContext';

import { validateOrder } from '../api/payments';

const SDK_OrderValidate = () => {
  const { addEventListener, removeEventListener } = useUnity();
  useEffect(() => {
    const handlePaymentRequest = (message) => {
      validateOrder(message);
    };

    addEventListener('OrderValidate', handlePaymentRequest);
    return () => removeEventListener('OrderValidate', handlePaymentRequest);
  }, [addEventListener, removeEventListener]);
};

export default SDK_OrderValidate;
