import { useEffect, useState } from 'react';

import { useApp } from '../contexts/AppContext';
import { useUnity } from '../contexts/UnityContext';

import { getSave } from '../api/saves';

import { cloudGetItem, cloudSetItem } from '../utils/cloudStorage';
import { STORAGE_KEY } from '../utils/constants';

const SDK_LoadData = () => {
  const { appId, user, isNeedSave } = useApp();
  const { isAppStarted, sendMessage, addEventListener, removeEventListener } =
    useUnity();
  const [saveData, setSaveData] = useState('');

  useEffect(() => {
    if (!!isAppStarted && !!saveData) {
      cloudGetItem(STORAGE_KEY.SAVE_DATA)
        .then((item) => {
          if (item !== null) {
            sendMessage('TeleLauncherSDK', 'OnSavesLoad', item);
          } else {
            sendMessage('TeleLauncherSDK', 'OnSavesLoad', saveData);
          }
        })
        .catch((error) => {
          console.error('Error retrieving data from Telegram Storage:', error);
          sendMessage('TeleLauncherSDK', 'OnSavesLoad', saveData);
        });
    }
  }, [isAppStarted, saveData, sendMessage]);

  useEffect(() => {
    const handleLoadSave = async () => {
      if (isNeedSave) {
        const result = await cloudGetItem(STORAGE_KEY.SAVE_DATA);
        if (!result) {
          const data = await getSave(user.id, appId);

          if (data && data.data) {
            setSaveData(JSON.stringify(data.data));
            cloudSetItem(STORAGE_KEY.SAVE_DATA, JSON.stringify(data.data));
          }
        } else {
          setSaveData(result);
        }
      } else {
        setSaveData('');
      }
    };

    addEventListener('LoadData', handleLoadSave);
    return () => removeEventListener('LoadData', handleLoadSave);
  }, [addEventListener, removeEventListener, appId]);
};

export default SDK_LoadData;
