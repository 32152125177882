import { useEffect, useState } from 'react';

import { useUnity } from '../contexts/UnityContext';

import { cloudGetItem } from '../utils/cloudStorage';
import { sendGTMEvent } from '../utils/analytics';
import { GA4_KEY } from '../utils/constants';

const SDK_LoadDataTelegram = () => {
  const { isAppStarted, sendMessage, addEventListener, removeEventListener } =
    useUnity();

  const [loadDataTelegram, setLoadDataTelegram] = useState(null);

  useEffect(() => {
    if (!!isAppStarted && !!loadDataTelegram) {
      cloudGetItem(loadDataTelegram)
        .then((item) => {
          sendMessage(
            'TeleLauncherSDK',
            'OnDataLoadTelegram',
            JSON.stringify({
              key: loadDataTelegram,
              data: item || null,
            })
          );
        })
        .catch((error) => {
          console.error('Error retrieving data from Telegram Storage:', error);
          sendMessage(
            'TeleLauncherSDK',
            'OnDataLoadTelegram',
            JSON.stringify({
              key: loadDataTelegram,
              data: null,
            })
          );
        })
        .finally(() => {
          setLoadDataTelegram(null);
          sendGTMEvent(GA4_KEY.unity_send_telegram_storage);
        });
    }
  }, [isAppStarted, loadDataTelegram, sendMessage]);

  useEffect(() => {
    const handleLoadDataTelegram = async (message) => {
      setLoadDataTelegram(message);
    };

    addEventListener('LoadDataTelegram', handleLoadDataTelegram);
    return () =>
      removeEventListener('LoadDataTelegram', handleLoadDataTelegram);
  }, [addEventListener, removeEventListener]);
};

export default SDK_LoadDataTelegram;
