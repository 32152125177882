import { useEffect } from 'react';

import { useUnity } from '../contexts/UnityContext';

const SDK_PaymentRequest = (
  setPaymentProduct,
  setIsPopupVisible,
  setShowConnectWallet
) => {
  const { addEventListener, removeEventListener } = useUnity();

  useEffect(() => {
    const handlePaymentRequest = async (message) => {
      let productInfo;
      try {
        const parsed = JSON.parse(message);
        productInfo = parsed;
      } catch (e) {
        productInfo = {
          productId: message,
        };
      }
      setPaymentProduct(productInfo);
      setShowConnectWallet(false);
      setIsPopupVisible(true);
    };

    addEventListener('PaymentRequest', handlePaymentRequest);
    return () => removeEventListener('PaymentRequest', handlePaymentRequest);
  }, [
    addEventListener,
    removeEventListener,
    setPaymentProduct,
    setIsPopupVisible,
    setShowConnectWallet,
  ]);
};

export default SDK_PaymentRequest;
