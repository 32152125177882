import { useEffect, useCallback, useState } from 'react';

export const useWakeLock = () => {
  const [wakeLock, setWakeLock] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);

  const checkSupport = async () => {
    if (!('wakeLock' in navigator)) {
      return false;
    }

    try {
      const lock = await navigator.wakeLock.request('screen');
      await lock.release();
      return true;
    } catch (err) {
      if (err.name === 'NotAllowedError') {
        return false;
      }
      return true;
    }
  };

  const [isSupported, setIsSupported] = useState(false);

  // Проверяем поддержку при монтировании
  useEffect(() => {
    checkSupport().then(setIsSupported);
  }, []);

  const enable = useCallback(async () => {
    if (!isSupported) {
      console.log('Wake Lock API не поддерживается или заблокирован');
      return false;
    }

    try {
      const lock = await navigator.wakeLock.request('screen');
      setWakeLock(lock);
      setIsEnabled(true);
      return true;
    } catch (err) {
      console.log('Ошибка Wake Lock:', err);
      return false;
    }
  }, [isSupported]);

  const disable = useCallback(async () => {
    if (wakeLock) {
      try {
        await wakeLock.release();
        setWakeLock(null);
        setIsEnabled(false);
        return true;
      } catch (err) {
        console.log('Ошибка при отключении Wake Lock:', err);
        return false;
      }
    }
    return false;
  }, [wakeLock]);

  useEffect(() => {
    if (!isEnabled) return;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        enable();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isEnabled, enable]);

  useEffect(() => {
    return () => {
      if (wakeLock) {
        wakeLock.release();
      }
    };
  }, [wakeLock]);

  return {
    enable,
    disable,
    isSupported,
  };
};
