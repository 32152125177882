import { useEffect } from 'react';

import { sendAnalyticsEvent } from '../api/event';

import { useApp } from '../contexts/AppContext';

const SDK_SetUnityLoadProgress = (
  sendMessage,
  addEventListener,
  removeEventListener,
  setUnityLoadProgress,
  unityLoadProgress
) => {
  const { fullLoaded, allStartTime, setAllStartTime } = useApp();

  useEffect(() => {
    if (fullLoaded) {
      sendMessage('TeleLauncherSDK', 'OnSDKReady');

      const transformTimings = (data) => {
        const result = {};
        let prevTime = data.start;
        let total = 0;
        let unityTotal = 0;

        Object.entries(data).forEach(([key, value]) => {
          let newKey = key;
          if (key.startsWith('unity_')) {
            const num = parseFloat(key.replace('unity_', ''));
            newKey = `unity_${num.toFixed(2)}`;
            const diffSeconds = (value - prevTime) / 1000;
            unityTotal += diffSeconds;
          }

          const diffSeconds = (value - prevTime) / 1000;
          result[newKey] = parseFloat(diffSeconds.toFixed(3));

          total += diffSeconds;
          prevTime = value;
        });

        result.total = +total.toFixed(3);
        result.total_unity = +unityTotal.toFixed(3);
        delete result.start;
        return result;
      };

      const transformedTimes = transformTimings(allStartTime);

      sendAnalyticsEvent('start_time_full', transformedTimes);
    }
  }, [fullLoaded]);

  useEffect(() => {
    const handleProgress = (message) => {
      setAllStartTime((prev) => ({
        ...prev,
        [`unity_${+message}`]: new Date().getTime(),
      }));
      const roundedProgress = Number(message).toFixed(2) * 100;

      if (unityLoadProgress !== roundedProgress) {
        setUnityLoadProgress(roundedProgress);
      }
    };

    addEventListener('SetUnityLoadProgress', handleProgress);
    return () => removeEventListener('SetUnityLoadProgress', handleProgress);
  }, [unityLoadProgress, addEventListener, removeEventListener]);
};

export default SDK_SetUnityLoadProgress;
