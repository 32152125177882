// Утилиты для работы с чанками
async function setItemInChunks(key, value, chunkSize = 4000) {
  const valueString = JSON.stringify(value);
  const chunks = [];

  for (let i = 0; i < valueString.length; i += chunkSize) {
    chunks.push(valueString.slice(i, i + chunkSize));
  }

  const metadata = {
    totalChunks: chunks.length,
    totalLength: valueString.length,
    timestamp: Date.now(),
  };

  await window.Telegram.WebApp.CloudStorage.setItem(
    `${key}_metadata`,
    JSON.stringify(metadata)
  );

  for (let i = 0; i < chunks.length; i++) {
    await window.Telegram.WebApp.CloudStorage.setItem(
      `${key}_chunk_${i}`,
      chunks[i]
    );
  }
}

async function getItemFromChunks(key) {
  const metadataStr = await new Promise((resolve, reject) => {
    window.Telegram.WebApp.CloudStorage.getItem(
      `${key}_metadata`,
      (error, value) => {
        if (error) reject(error);
        else resolve(value);
      }
    );
  });

  if (!metadataStr) return null;
  const metadata = JSON.parse(metadataStr);

  const chunks = [];
  for (let i = 0; i < metadata.totalChunks; i++) {
    const chunk = await new Promise((resolve, reject) => {
      window.Telegram.WebApp.CloudStorage.getItem(
        `${key}_chunk_${i}`,
        (error, value) => {
          if (error) reject(error);
          else resolve(value);
        }
      );
    });
    chunks.push(chunk);
  }

  const valueString = chunks.join('');
  return JSON.parse(valueString);
}

// CloudStorage функции
export async function cloudSetItem(key, value) {
  return new Promise(async (resolve, reject) => {
    if (!window.Telegram?.WebApp?.CloudStorage) {
      reject(new Error('Telegram WebApp CloudStorage не доступен'));
      return;
    }

    try {
      const valueString = JSON.stringify(value);

      // Если данные большие, используем чанки
      if (valueString.length > 4000) {
        await setItemInChunks(key, value);
        resolve();
        return;
      }

      // Для небольших данных используем стандартный метод
      window.Telegram.WebApp.CloudStorage.setItem(key, valueString, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}

export async function cloudGetItem(key) {
  return new Promise(async (resolve, reject) => {
    if (!window.Telegram?.WebApp?.CloudStorage) {
      reject(new Error('Telegram WebApp CloudStorage не доступен'));
      return;
    }

    try {
      // Проверяем наличие метаданных для чанков
      const hasMetadata = await new Promise((resolve) => {
        window.Telegram.WebApp.CloudStorage.getItem(
          `${key}_metadata`,
          (error, value) => {
            resolve(!!value);
          }
        );
      });

      if (hasMetadata) {
        const value = await getItemFromChunks(key);
        resolve(value);
        return;
      }

      // Если нет метаданных, получаем обычным способом
      window.Telegram.WebApp.CloudStorage.getItem(key, (error, value) => {
        if (error) {
          reject(error);
        } else {
          try {
            const parsedValue = value ? JSON.parse(value) : null;
            resolve(parsedValue);
          } catch (parseError) {
            reject(new Error('Ошибка при парсинге данных из хранилища'));
          }
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}

export function cloudGetItems(keys) {
  return new Promise((resolve, reject) => {
    if (!window.Telegram?.WebApp?.CloudStorage) {
      reject(new Error('Telegram WebApp CloudStorage не доступен'));
      return;
    }

    window.Telegram.WebApp.CloudStorage.getItems(keys, (error, values) => {
      if (error) {
        reject(error);
        return;
      }

      try {
        // Преобразуем значения из JSON строк в объекты
        const parsedValues = {};
        for (const [key, value] of Object.entries(values)) {
          parsedValues[key] = value ? JSON.parse(value) : null;
        }
        resolve(parsedValues);
      } catch (parseError) {
        reject(new Error('Ошибка при парсинге данных из хранилища'));
      }
    });
  });
}

export async function cloudRemoveItem(key) {
  return new Promise(async (resolve, reject) => {
    if (!window.Telegram?.WebApp?.CloudStorage) {
      reject(new Error('Telegram WebApp CloudStorage не доступен'));
      return;
    }

    try {
      // Проверяем, есть ли метаданные чанков
      const hasMetadata = await new Promise((resolve) => {
        window.Telegram.WebApp.CloudStorage.getItem(
          `${key}_metadata`,
          (error, value) => {
            resolve(!!value);
          }
        );
      });

      if (hasMetadata) {
        const metadata = JSON.parse(
          await new Promise((resolve, reject) => {
            window.Telegram.WebApp.CloudStorage.getItem(
              `${key}_metadata`,
              (error, value) => {
                if (error) reject(error);
                else resolve(value);
              }
            );
          })
        );

        // Удаляем все чанки и метаданные
        const promises = [];
        for (let i = 0; i < metadata.totalChunks; i++) {
          promises.push(
            new Promise((resolve, reject) => {
              window.Telegram.WebApp.CloudStorage.removeItem(
                `${key}_chunk_${i}`,
                (error) => {
                  if (error) reject(error);
                  else resolve();
                }
              );
            })
          );
        }
        promises.push(
          new Promise((resolve, reject) => {
            window.Telegram.WebApp.CloudStorage.removeItem(
              `${key}_metadata`,
              (error) => {
                if (error) reject(error);
                else resolve();
              }
            );
          })
        );

        await Promise.all(promises);
        resolve();
        return;
      }

      // Если нет чанков, удаляем обычным способом
      window.Telegram.WebApp.CloudStorage.removeItem(key, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}

export async function cloudRemoveItems(keys) {
  return new Promise((resolve, reject) => {
    if (!window.Telegram?.WebApp?.CloudStorage) {
      reject(new Error('Telegram WebApp CloudStorage не доступен'));
      return;
    }

    window.Telegram.WebApp.CloudStorage.removeItems(keys, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
}

export async function cloudGetKeys() {
  return new Promise((resolve, reject) => {
    if (!window.Telegram?.WebApp?.CloudStorage) {
      reject(new Error('Telegram WebApp CloudStorage не доступен'));
      return;
    }

    window.Telegram.WebApp.CloudStorage.getKeys((error, keys) => {
      if (error) {
        reject(error);
      } else {
        resolve(keys);
      }
    });
  });
}

// LocalStorage функции
export function localGetItem(key, callback = null) {
  const itemString = localStorage.getItem(key);
  if (!itemString) {
    return null;
  }
  const item = JSON.parse(itemString);
  const now = Date.now();
  if (item.expiry && now > item.expiry) {
    if (callback) {
      callback(item.value);
    }
    localRemoveItem(key);
    return null;
  }
  return item.value;
}

export function localSetItem(key, value, expireInSeconds = null) {
  const now = Date.now();
  const item = {
    value,
    expiry: expireInSeconds ? now + expireInSeconds * 1000 : null,
  };
  const itemString = JSON.stringify(item);
  localStorage.setItem(key, itemString);
}

export function localRemoveItem(key) {
  localStorage.removeItem(key);
}

export function localGetKeys() {
  return Object.keys(localStorage);
}

export function localFlush() {
  localStorage.clear();
}

// Пример использования:
// async function exampleUsage() {
//   try {
//     // Сохранение больших данных
//     const largeData = { /* большой объект */ };
//     await cloudSetItem('largeKey', largeData);
//
//     // Получение больших данных
//     const retrievedData = await cloudGetItem('largeKey');
//
//     // Локальное хранение с истечением срока
//     localSetItem('tempKey', { data: 'value' }, 3600); // истекает через час
//
//     // Получение локальных данных
//     const localData = localGetItem('tempKey');
//   } catch (error) {
//     console.error('Ошибка:', error);
//   }
// }
