import axios from 'axios';

import GAMES_CONFIG from '../GamesConfig';

const BASE_URL = process.env.REACT_APP_SERVICE_URL;

const unityCacheEndpoints = {
  getUnityCache: '/api/get-etag',
};

export const getAllCache = async (app_id, id, isNeedSave = true) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${
        unityCacheEndpoints.getUnityCache
      }/${id}?app_id=${app_id}&url=${
        GAMES_CONFIG[app_id].gameUrls.dataUrl
      }&save=${isNeedSave ? 1 : 0}`
    );

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Сache retrieval failed:', error);
    return null;
  }
};
