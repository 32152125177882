import React, {useState, useEffect} from 'react';
import confetti from 'canvas-confetti';

import {useApp} from '../../../../contexts/AppContext';

import {GA4_KEY} from '../../../../utils/constants';
import {sendGTMEvent} from '../../../../utils/analytics';

import GAMES_CONFIG from '../../../../GamesConfig';

import styles from './OnboardingLuckyVerse.module.css';

const screens = [
    {
        title: 'Ready?',
        subtitle: 'SPIN to Get $LUCK tokens',
        description: 'Spin slots, earn tokens, win big!',
        icon: (
            <svg viewBox='0 0 24 24' width='100%' height='100%' fill='#8B27F5'>
                <path
                    d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z'/>
            </svg>
        ),
    },
    {
        title: 'PvP & Leaderboard',
        subtitle: 'Earn EVEN MORE $LUCK tokens',
        description:
            'Climb the rankings, invite friends, steal from rivals, earn more $LUCK!',
        icon: (
            <svg viewBox='0 0 24 24' width='100%' height='100%' fill='#8B27F5'>
                <path
                    d='M19 5h-2V3H7v2H5c-1.1 0-2 .9-2 2v1c0 2.55 1.92 4.63 4.39 4.94.63 1.5 1.98 2.63 3.61 2.96V19H7v2h10v-2h-4v-3.1c1.63-.33 2.98-1.46 3.61-2.96C19.08 12.63 21 10.55 21 8V7c0-1.1-.9-2-2-2zM5 8V7h2v3.82C5.84 10.4 5 9.3 5 8zm14 0c0 1.3-.84 2.4-2 2.82V7h2v1z'/>
            </svg>
        ),
    },
    {
        title: 'HOLD LUCK!',
        subtitle: 'Hodl $LUCK TOKEN',
        description:
            'Open loot boxes, get free spins, shields and participate in AIRDROPS!',
        icon: (
            <svg viewBox='0 0 24 24' width='100%' height='100%' fill='#8B27F5'>
                <path
                    d='M20 6h-2.18c.11-.31.18-.65.18-1 0-1.66-1.34-3-3-3-1.05 0-1.96.54-2.5 1.35l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20v6z'/>
            </svg>
        ),
    },
    {
        title: '$LUCK Token Listing',
        subtitle: '1 $LUCK token',
        description:
            'Exchange listing coming soon. Play and earn — make every spin count!',
        icon: (
            <svg viewBox='0 0 24 24' width='100%' height='100%' fill='#8B27F5'>
                <path d='M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z'/>
            </svg>
        ),
    },
    {
        title: 'Invite Friends',
        subtitle: 'Invite a premium TG user and get +15 $LUCK 🍀',
        description:
            'Or get +5 $LUCK 🍀 for each general user. Increase your influence — grow faster with $LUCK!',
        icon: (
            <svg viewBox='0 0 24 24' width='100%' height='100%' fill='#8B27F5'>
                <path
                    d='M16 17v2H2v-2s0-4 7-4 7 4 7 4m-3.5-9.5A3.5 3.5 0 109 11a3.5 3.5 0 003.5-3.5m3.44 5.5A5.32 5.32 0 0118 17v2h4v-2s0-3.63-6.06-4M15 4a3.39 3.39 0 00-1.93.59 5 5 0 010 5.82A3.39 3.39 0 0015 11a3.5 3.5 0 000-7z'/>
            </svg>
        ),
    },
];

const CardContent = ({screen, isLast, onShare, onClick, animationKey}) => {
    const [buttonsEnabled, setButtonsEnabled] = useState(false);

    useEffect(() => {
        const element = document.querySelectorAll('.animated');
        setTimeout(() => {
            element.forEach((element) => {
                element.classList.add(`${styles.animatedElement}`);
            });

            setTimeout(() => {
                setButtonsEnabled(true);
            }, 1000);
        }, 650);

        return () => {
            element.forEach((element) => {
                element.classList.remove(`${styles.animatedElement}`);
            });
            setButtonsEnabled(false);
        };
    }, [animationKey]);

    return (
        <div className={styles.cardContent}>
            <div className={styles.iconWrapper}>{screen.icon}</div>
            <h1 className={styles.title}>{screen.title}</h1>
            <h2 className={`${styles.subtitle} animated`} data-text={screen.subtitle}>
                {screen.subtitle}
            </h2>
            <p className={styles.description}>{screen.description}</p>
            <div className={`${styles.buttonContainer} animated`}>
                <button
                    className={`${styles.button} ${styles.mainButton}`}
                    onClick={buttonsEnabled ? onClick : undefined}
                    disabled={!buttonsEnabled}
                >
                    {isLast ? 'Start' : 'Next'}
                </button>

                {isLast && (
                    <button
                        className={`${styles.button} ${styles.shareButton}`}
                        onClick={buttonsEnabled ? onShare : undefined}
                        disabled={!buttonsEnabled}
                    >
                        Share
                    </button>
                )}
            </div>
        </div>
    );
};

const OnboardingLuckyVerse = ({setFirstOnboarding}) => {
    const {appId, user, telegram} = useApp();

    const [currentScreen, setCurrentScreen] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [previousScreen, setPreviousScreen] = useState(null);

    useEffect(() => {
        // Создаем canvas для confetti
        const canvas = document.createElement('canvas');
        canvas.style.position = 'fixed';
        canvas.style.top = '0';
        canvas.style.left = '0';
        canvas.style.width = '100vw';
        canvas.style.height = '100vh';
        canvas.style.pointerEvents = 'none';
        canvas.style.zIndex = '999999';
        document.body.appendChild(canvas);

        // Настраиваем confetti
        const myConfetti = confetti.create(canvas, {
            resize: true,
            useWorker: true,
        });

        window.myConfetti = myConfetti;

        return () => {
            document.body.removeChild(canvas);
        };
    }, []);

    const fireConfetti = () => {
        if (!window.myConfetti) return;

        const count = 100;
        const defaults = {
            origin: {y: 0.7},
        };

        function fire(particleRatio, opts) {
            window.myConfetti({
                ...defaults,
                ...opts,
                particleCount: Math.floor(count * particleRatio),
            });
        }

        fire(0.25, {
            spread: 26,
            startVelocity: 55,
        });

        fire(0.2, {
            spread: 60,
        });

        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8,
        });

        fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
        });

        fire(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    };

    const handleClick = () => {
        if (isAnimating || currentScreen === screens.length - 1) {
            if (currentScreen === screens.length - 1) {
                setFirstOnboarding(false);
            }
            return;
        }

        setIsAnimating(true);
        setPreviousScreen(currentScreen);
        fireConfetti();

        setCurrentScreen((prev) => prev + 1);

        setTimeout(() => {
            setIsAnimating(false);
            setPreviousScreen(null);
        }, 600);
    };

    const handleShare = () => {
        const url = `${GAMES_CONFIG[appId].telegramUrl}/app?startapp=${user.id}`;

        const shareText = GAMES_CONFIG[appId].shareDefaultText;

        sendGTMEvent(GA4_KEY.untity_share);

        const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
            url
        )}&text=${encodeURIComponent(shareText)}`;

        const platform = telegram.platform;

        if (platform === 'weba') {
            telegram.openLink(shareUrl);
        } else {
            telegram.openTelegramLink(shareUrl);
        }
    };

    return (
        <div className={styles.app}>
            {previousScreen !== null && (
                <div className={`${styles.screen} ${styles.exitingLeft}`}>
                    <div className={styles.card}>
                        <CardContent
                            screen={screens[previousScreen]}
                            isLast={previousScreen === screens.length - 1}
                            onShare={handleShare}
                            onClick={handleClick}
                        />
                    </div>
                </div>
            )}
            <div
                className={`${styles.screen} ${
                    isAnimating ? styles.enteringRight : ''
                }`}
            >
                <div className={styles.card}>
                    <CardContent
                        screen={screens[currentScreen]}
                        isLast={currentScreen === screens.length - 1}
                        onShare={handleShare}
                        onClick={handleClick}
                        animationKey={currentScreen}
                    />
                </div>
            </div>
        </div>
    );
};

export default OnboardingLuckyVerse;
