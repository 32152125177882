import { useEffect } from 'react';

import { clearAppCaches } from '../utils/clearAppCaches';
import {
  cloudRemoveItem,
  cloudSetItem,
  localFlush,
  localSetItem,
} from '../utils/cloudStorage';
import { STORAGE_KEY } from '../utils/constants';
import { preloadMedia } from '../utils/preloadService';
import { handleUnityCache } from '../utils/handleUnityCache';
import { getAllCache } from '../api/allCache';

export const useCheckCaches = (
  isExists,
  appId,
  user,
  setIsCacheCleared,
  isNeedSave
) => {
  const initializeApp = async () => {
    try {
      const allCache = await getAllCache(appId, user.id, isNeedSave);
      if (isExists === false) {
        await clearAppCaches(appId);
        await Promise.all([
          cloudRemoveItem(STORAGE_KEY.PAYMENT_HASH),
          cloudRemoveItem(STORAGE_KEY.SAVE_DATA),
          cloudRemoveItem(STORAGE_KEY.PROMO_DATA),
        ]);
        await cloudSetItem(STORAGE_KEY.SAVE_CACHE, null);
        localFlush();
        if (allCache?.etag) {
          localSetItem(`${STORAGE_KEY.UNITY_CACHE}${appId}`, allCache.etag);
        }
        localSetItem(`${STORAGE_KEY.NEED_CLEAR_UNITY_CACHE}${appId}`, true);
      } else {
        await handleUnityCache(allCache, appId, user.id, isNeedSave);
      }
      await preloadMedia();
      setIsCacheCleared(true);
    } catch (error) {
      console.error('Error during application initialization:', error);
      setIsCacheCleared(true);
    }
  };

  useEffect(() => {
    if (isExists !== undefined && isExists !== null) {
      initializeApp();
    }
  }, [appId, isExists]);

  return null;
};
