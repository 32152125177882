import { useEffect } from 'react';

import { useApp } from '../contexts/AppContext';
import { useUnity } from '../contexts/UnityContext';

import { GA4_KEY } from '../utils/constants';
import { sendGTMEvent } from '../utils/analytics';

import GAMES_CONFIG from '../GamesConfig';

const SDK_ShareToStory = () => {
  const { appId, user, isTelegram, telegram } = useApp();
  const { addEventListener, removeEventListener } = useUnity();
  
  useEffect(() => {
    const handleShareToStory = (mediaUrl) => {
      if (!isTelegram) {
        alert('This function is only available in the Telegram app.');
        return;
      }

      try {
        const linkUrl = `${GAMES_CONFIG[appId].telegramUrl}/app?startapp=${user.id}`;
        const text = `Join 🚀 TimeMaster - ${linkUrl}`;

        const params = {
          text: text,
          widget_link: {
            url: linkUrl,
            name: 'Learn more',
          },
        };

        telegram.shareToStory(mediaUrl, params);
        sendGTMEvent(GA4_KEY.untity_share_story);
      } catch (error) {
        alert(`Error while publishing: ${error.message}`);
      }
    };

    addEventListener('ShareToStory', handleShareToStory);
    return () => removeEventListener('ShareToStory', handleShareToStory);
  }, [addEventListener, removeEventListener, appId]);
};

export default SDK_ShareToStory;
