import axios from 'axios';

const BASE_URL = process.env.REACT_APP_SERVICE_URL;

const existsEndpoints = {
  checkIsUserExists: '/api/user-exists',
};

export const checkIsUserExists = async (id) => {
  try {
    const { data: response } = await axios.get(
      `${BASE_URL}${existsEndpoints.checkIsUserExists}/${id}`
    );

    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { exists: false };
    }

    console.error('Failed to get checkFullReset:', error);
    return { exists: false };
  }
};
