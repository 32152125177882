import GAMES_CONFIG from '../GamesConfig';

const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(src);
    img.onerror = reject;
    img.src = src;
  });
};

export const preloadMedia = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const app_id = searchParams.get('app_id');

  const preloadPromises = [
    preloadImage(GAMES_CONFIG[app_id || 1].logo),
    '/assetsLuckyVerse/LoadingScreen.webp',
  ];
  return Promise.all(preloadPromises);
};
