import { useEffect } from 'react';

import { useApp } from '../contexts/AppContext';
import { useUnity } from '../contexts/UnityContext';

import { GA4_KEY } from '../utils/constants';
import { sendGTMEvent } from '../utils/analytics';
import promoHandlerUtility from '../utils/promoHandlerUtility';

import GAMES_CONFIG from '../GamesConfig';

const SDK_Share = () => {
  const { appId, user, telegram } = useApp();
  const { addEventListener, removeEventListener } = useUnity();

  useEffect(() => {
    const handleShare = (message) => {
      const url = `${GAMES_CONFIG[appId].telegramUrl}/app?startapp=${user.id}`;

      const shareText = message
        ? GAMES_CONFIG[appId][message]
          ? GAMES_CONFIG[appId][message]
          : message
        : GAMES_CONFIG[appId].shareDefaultText;

      sendGTMEvent(GA4_KEY.untity_share);

      // promoHandlerUtility.sendPromoCallback(user.id);

      const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
        url
      )}&text=${encodeURIComponent(shareText)}`;

      const platform = telegram.platform;

      if (platform === 'weba') {
        telegram.openLink(shareUrl);
      } else {
        telegram.openTelegramLink(shareUrl);
      }
    };

    addEventListener('Share', handleShare);

    return () => removeEventListener('Share', handleShare);
  }, [addEventListener, removeEventListener, appId]);
};

export default SDK_Share;
