import React, { useState } from 'react';
import { Unity } from 'react-unity-webgl';

import { useUnity } from '../../contexts/UnityContext';

import { ADS_ID } from '../../utils/constants';

import { useDevicePixelRatio } from '../../hooks/useDevicePixelRatio';
import useLoadTappadsTasks from '../../hooks/useLoadTappadsTasks';
import usePlayTappads from '../../hooks/usePlayTappads';
import useSafeAreaChanged from '../../hooks/useSafeAreaChanged';
import useCheckIsHavePayment from '../../hooks/useCheckIsHavePayment';

import SDK_GetBalance from '../../sdk/SDK_GetBalance';
import SDK_GetLeaderboardData from '../../sdk/SDK_GetLeaderboardData';
import SDK_GetProducts from '../../sdk/SDK_GetProducts';
import SDK_HapticTrigger from '../../sdk/SDK_HapticTrigger';
import SDK_LoadData from '../../sdk/SDK_LoadData';
import SDK_LoadDataTelegram from '../../sdk/SDK_LoadDataTelegram';
import SDK_OrderValidate from '../../sdk/SDK_OrderValidate';
import SDK_PaymentRequest from '../../sdk/SDK_PaymentRequest';
import SDK_ReloadApp from '../../sdk/SDK_ReloadApp';
import SDK_SaveData from '../../sdk/SDK_SaveData';
import SDK_SaveDataTelegram from '../../sdk/SDK_SaveDataTelegram';
import SDK_SaveRefLinkToClipboard from '../../sdk/SDK_SaveRefLinkToClipboard';
import SDK_Share from '../../sdk/SDK_Share';
import SDK_ShareToStory from '../../sdk/SDK_ShareToStory';
import SDK_ShowAds from '../../sdk/SDK_ShowAds';
import SDK_ShowRewardedAd from '../../sdk/SDK_ShowRewardedAd';
import SDK_AddHomeIcon from '../../sdk/SDK_AddHomeIcon';
import SDK_LoadAvatarByUserId from '../../sdk/SDK_LoadAvatarByUserId';
import SDK_SetScreenCanGoSleep from '../../sdk/SDK_SetScreenCanGoSleep';
import SDK_OnAppFocusChanged from '../../sdk/SDK_OnAppFocusChanged';

import Popup from '../../components/Popup/Popup';
import Modal from '../../components/Modal/Modal';
import Tads from '../../components/Tads/Tads';
import Tappads from '../../components/Tappads/Tappads';

import styles from './GamePage.module.css';

function GamePage() {
  const devicePixelRatio = useDevicePixelRatio();

  const { unityProvider, sendMessage } = useUnity();

  const [paymentProduct, setPaymentProduct] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [AdNumber, setAdNumber] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [showConnectWallet, setShowConnectWallet] = useState(false);

  useCheckIsHavePayment(setIsPopupVisible, setIsLoading, setPaymentProduct);
  useSafeAreaChanged();

  SDK_ReloadApp();
  SDK_GetProducts();
  SDK_GetBalance();
  SDK_Share();
  SDK_ShareToStory();
  SDK_SaveRefLinkToClipboard();
  SDK_GetLeaderboardData();
  SDK_LoadData();
  SDK_SaveData();
  SDK_SaveDataTelegram();
  SDK_LoadDataTelegram();
  SDK_PaymentRequest(
    setPaymentProduct,
    setIsPopupVisible,
    setShowConnectWallet
  );
  SDK_ShowRewardedAd();
  usePlayTappads();
  useLoadTappadsTasks();
  SDK_ShowAds(setIsModalVisible, setAdNumber);
  SDK_AddHomeIcon();
  SDK_OrderValidate();
  SDK_LoadAvatarByUserId();
  SDK_SetScreenCanGoSleep();
  SDK_OnAppFocusChanged();
  SDK_HapticTrigger();

  return (
    <div className={styles.container}>
      <Unity
        unityProvider={unityProvider}
        devicePixelRatio={devicePixelRatio}
        className={styles.unityContainer}
      />
      <div className={styles.popupContainer}>
        <Popup
          isVisible={isPopupVisible}
          setIsVisible={setIsPopupVisible}
          sendMessage={sendMessage}
          paymentProduct={paymentProduct}
          setPaymentProduct={setPaymentProduct}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          showConnectWallet={showConnectWallet}
          setShowConnectWallet={setShowConnectWallet}
        />
      </div>
      <Modal
        isVisible={isModalVisible}
        onClose={() => {
          sendMessage(
            'TeleLauncherSDK',
            'OnAdsResult',
            JSON.stringify({
              done: false,
            })
          );
          setIsModalVisible(false);
          setAdNumber(null);
        }}
        title='Choose your ad'
      >
        {AdNumber === ADS_ID.TADS && (
          <Tads
            setIsModalVisible={setIsModalVisible}
            setAdNumber={setAdNumber}
            sendMessage={sendMessage}
          />
        )}
        {AdNumber === ADS_ID.TAPPADS && (
          <Tappads
            setIsModalVisible={setIsModalVisible}
            setAdNumber={setAdNumber}
            sendMessage={sendMessage}
          />
        )}
      </Modal>
    </div>
  );
}

export default GamePage;
