import { useEffect } from 'react';
import { useUnity } from '../contexts/UnityContext';

const SDK_ReloadApp = () => {
  const { addEventListener, removeEventListener } = useUnity();
  
  useEffect(() => {
    const handleReloadApp = () => {
      window.location.reload();
    };
    addEventListener('ReloadApp', handleReloadApp);

    return () => {
      removeEventListener('ReloadApp', handleReloadApp);
    };
  }, [addEventListener, removeEventListener]);
};

export default SDK_ReloadApp;
