import GAMES_CONFIG from '../GamesConfig';

export const clearAppCaches = async (appId) => {
  const allCaches = await caches.keys();

  const cachePattern = `${GAMES_CONFIG[appId].subtitle}_${GAMES_CONFIG[appId].title}`;

  const matchingCaches = allCaches.filter((name) =>
    name.includes(cachePattern)
  );

  // Удаляем все найденные кэши
  const deletePromises = matchingCaches.map((cacheName) =>
    caches.delete(cacheName)
  );

  await Promise.all(deletePromises);
};
