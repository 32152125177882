import { useEffect, useState } from 'react';

import { useUnity } from '../contexts/UnityContext';

import useAdsgram from '../hooks/useAdsgram';

import { ADS_ID } from '../utils/constants';

const SDK_ShowAds = (setIsModalVisible, setAdNumber) => {
  const { isAppStarted, sendMessage, addEventListener, removeEventListener } =
    useUnity();
  const showAd = useAdsgram();
  const [rewardResult, setRewardResult] = useState(null);
  useEffect(() => {
    if (!!isAppStarted && !!rewardResult) {
      sendMessage(
        'TeleLauncherSDK',
        'OnAdsResult',
        JSON.stringify(rewardResult)
      );
      setRewardResult(null);
    }
  }, [isAppStarted, rewardResult, sendMessage, setRewardResult]);

  useEffect(() => {
    const handleShowAdd = async (message) => {
      if (+message === ADS_ID.ADSGRAM) {
        const result = await showAd();
        setRewardResult(result);
      }
      setAdNumber(+message);
      if (+message === ADS_ID.TADS || +message === ADS_ID.TAPPADS) {
        setIsModalVisible(true);
      }
    };

    addEventListener('ShowAds', handleShowAdd);
    return () => removeEventListener('ShowAds', handleShowAdd);
  }, [
    addEventListener,
    removeEventListener,
    setIsModalVisible,
    setAdNumber,
    showAd,
  ]);
};

export default SDK_ShowAds;
