export const getGamesUrls = async (url) => {
  const telegram = window.Telegram.WebApp;
  const platform = telegram.platform;
  const isDesktop = telegram
    ? ['tdesktop', 'macos', 'weba'].includes(platform)
      ? 'Desktop'
      : 'Mobile'
    : 'Mobile';
  try {
    const response = await fetch(url);
    const data = await response.json();
    const urlWithoutIndex = url.replace(/\/index\.json$/, '');
    return {
      loaderUrl: data.loaderUrl
        ? `${urlWithoutIndex}${data.loaderUrl.replace('{PLATFORM}', isDesktop)}`
        : undefined,
      dataUrl: data.dataUrl
        ? `${urlWithoutIndex}${data.dataUrl.replace('{PLATFORM}', isDesktop)}`
        : undefined,
      frameworkUrl: data.frameworkUrl
        ? `${urlWithoutIndex}${data.frameworkUrl.replace(
            '{PLATFORM}',
            isDesktop
          )}`
        : undefined,
      codeUrl: data.codeUrl
        ? `${urlWithoutIndex}${data.codeUrl.replace('{PLATFORM}', isDesktop)}`
        : undefined,
      streamingAssetsUrl: data.streamingAssetsUrl
        ? `${urlWithoutIndex}${data.streamingAssetsUrl.replace(
            '{PLATFORM}',
            isDesktop
          )}`
        : undefined,
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};
