import makeRequest from './apiConfig';

const saveEndpoints = {
  saveEndpoint: '/api/cloud/save',
};

export const getSave = async (user_id, game_id) => {
  try {
    const response = await makeRequest({
      method: 'GET',
      endpoint: `${saveEndpoints.saveEndpoint}?user_id=${user_id}&game_id=${game_id}`,
    });

    return response;
  } catch (error) {
    if (error.response.status === 404) {
      return {
        data: null,
      };
    }
    console.error('Error retrieving data:', error);
    throw error;
  }
};

export const setSave = async (user_id, game_id, payload) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      endpoint: `${saveEndpoints.saveEndpoint}?user_id=${user_id}&game_id=${game_id}`,
      body: {
        payload: payload,
      },
    });

    return response;
  } catch (error) {
    console.error('Error retrieving data:', error);
    throw error;
  }
};
