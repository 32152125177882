import { useEffect } from 'react';

import { useUnity } from '../contexts/UnityContext';

import { cloudSetItem } from '../utils/cloudStorage';
import { sendGTMEvent } from '../utils/analytics';
import { GA4_KEY } from '../utils/constants';

const SDK_SaveDataTelegram = () => {
  const { addEventListener, removeEventListener } = useUnity();

  useEffect(() => {
    const handleSaveDataTelegram = async (message) => {
      const { key, data } = JSON.parse(message);
      if (!!key && !!data) {
        cloudSetItem(key, data);
        alert(`save in tg with key:${key}`);
        sendGTMEvent(GA4_KEY.unity_save_data_to_telegram_storage);
      }
    };

    addEventListener('SaveDataTelegram', handleSaveDataTelegram);
    return () =>
      removeEventListener('SaveDataTelegram', handleSaveDataTelegram);
  }, [addEventListener, removeEventListener]);
};

export default SDK_SaveDataTelegram;
