import { useCallback, useRef } from 'react';

import { useApp } from '../contexts/AppContext';

import GAMES_CONFIG from '../GamesConfig';

const useAdsgram = () => {
  const { appId } = useApp();

  const AdControllerRef = useRef(undefined);

  const initAd = useCallback((blockId) => {
    if (window.Adsgram) {
      AdControllerRef.current = window.Adsgram.init({ blockId });
    }
  }, []);

  const showAd = useCallback(() => {
    initAd(GAMES_CONFIG[appId].adsgramKey);

    if (AdControllerRef.current) {
      return AdControllerRef.current
        .show()
        .then((result) => {
          return { ...result, placement: GAMES_CONFIG[appId].title };
        })
        .catch((result) => {
          return { ...result, placement: GAMES_CONFIG[appId].title };
        });
    } else {
      return {
        error: true,
        done: false,
        state: 'load',
        description: 'Adsgram script not loaded',
        placement: GAMES_CONFIG[appId].title,
      };
    }
  }, [initAd]);

  return showAd;
};

export default useAdsgram;
