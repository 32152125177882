import { useEffect, useState } from 'react';

import { useApp } from '../contexts/AppContext';

import { GA4_KEY } from '../utils/constants';
import { sendGTMEvent } from '../utils/analytics';
import { useUnity } from '../contexts/UnityContext';

const SDK_AddHomeIcon = () => {
  const [status, setStatus] = useState('');
  const { telegram } = useApp();
  const { isAppStarted, sendMessage, addEventListener, removeEventListener } =
    useUnity();

  useEffect(() => {
    const handleHomeScreenAdded = () => {
      sendMessage('TeleLauncherSDK', 'OnHomeIconAdded');
    };

    telegram.onEvent('homeScreenAdded', handleHomeScreenAdded);

    return () => {
      telegram.offEvent('homeScreenAdded', handleHomeScreenAdded);
    };
  }, [sendMessage]);

  useEffect(() => {
    const checkHomeScreenStatus = () => {
      if (!!isAppStarted && !!status) {
        let statusNumber;
        switch (status) {
          case 'unsupported':
            statusNumber = 0;
            break;
          case 'unknown':
            statusNumber = 1;
            break;
          case 'added':
            statusNumber = 2;
            break;
          case 'missed':
            statusNumber = 3;
            break;
          default:
            statusNumber = 0;
            break;
        }

        sendMessage(
          'TeleLauncherSDK',
          'OnAddHomeIconResult',
          JSON.stringify({ result: statusNumber })
        );
        if (status === 'missed') {
          telegram.addToHomeScreen();
          sendGTMEvent(GA4_KEY.added_app_icon);
        }
      }
    };

    checkHomeScreenStatus();
  }, [isAppStarted, status, sendMessage]);

  useEffect(() => {
    const handleAddHomeIcon = async () => {
      try {
        if (telegram.platform === 'weba') {
          setStatus('unsupported');
        } else {
          telegram.checkHomeScreenStatus((status) => {
            setStatus(status);
          });
        }
      } catch (error) {
        setStatus('unsupported');
      }
    };

    addEventListener('AddHomeIcon', handleAddHomeIcon);
    return () => removeEventListener('AddHomeIcon', handleAddHomeIcon);
  }, [addEventListener, removeEventListener]);
};

export default SDK_AddHomeIcon;
