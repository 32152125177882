import makeRequest from './apiConfig';

import { promoHandlerUtility } from '../utils/promoHandlerUtility';

const authEndpoints = {
  auth: '/api/app-auth',
  create: '/api/create-user',
  setPhoto: '/api/photo-url',
};

export const getAuth = async (userData) => {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const appId = searchParams.get('app_id');

    const response = await makeRequest({
      method: 'POST',
      endpoint: authEndpoints.auth,
      body: JSON.stringify({
        user_id: userData.id,
        username: userData.username || 'unknown',
        app_id: appId,
      }),
    });

    return response;
  } catch (error) {
    console.error('Authentication failed:', error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const fullname = userData.last_name
      ? `${userData.first_name} ${userData.last_name}`
      : userData.first_name;

    await makeRequest({
      method: 'POST',
      endpoint: authEndpoints.create,
      body: JSON.stringify({
        user_id: userData.id,
        fullname,
        username: userData.username,
        ...userData,
      }),
    });

    if (userData.referer_id) {
      promoHandlerUtility.handlePromoCode(
        userData.referer_id,
        userData?.id,
        false
      );
    }
  } catch (error) {
    if (error.status === 409) {
      if (userData.referer_id) {
        promoHandlerUtility.handlePromoCode(
          userData.referer_id,
          userData?.id,
          true
        );
      }
      console.log('User already exists:', error);
    } else {
      console.error('Error occurred while creating user:', error);
      throw error;
    }
  }
};

export const setPhoto = async (userData) => {
  try {
    await makeRequest({
      method: 'PUT',
      endpoint: authEndpoints.setPhoto,
      body: JSON.stringify({ photo_url: userData.photo_url }),
    });
  } catch (error) {
    if (error.status === 405) {
      console.log(
        'Set photo request failed with 405 status (Method Not Allowed). Skipping.'
      );
    } else {
      console.error('Error occurred while sending photo:', error);
      throw error;
    }
  }
};
