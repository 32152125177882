import React, { useEffect, useState, useRef } from 'react';

import { useUnity } from '../../../../contexts/UnityContext';
import { useApp } from '../../../../contexts/AppContext';

import { useInitializeApp } from '../../../../hooks/useInitializeApp';
import useDeviceOrientation from '../../../../hooks/useDeviceOrientation';

import OnboardingLuckyVerse from '../OnboardingLuckyVerse/OnboardingLuckyVerse';

import styles from './LoadingLuckyVerse.module.css';

const PROGRESS_STEP = 0.5;
const FINAL_PROGRESS = 100;

function LoadingLuckyVerse() {
  const isVerticalOrientation = useDeviceOrientation();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [firstOnboarding, setFirstOnboarding] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const { telegram, appLoaded, setFullLoaded } = useApp();
  const { loadingProgression, unityLoadProgress } = useUnity();

  const [displayProgress, setDisplayProgress] = useState(0);
  const animationRef = useRef(null);

  const { progress } = useInitializeApp(setFirstOnboarding);
  const gameProgress = Math.floor(loadingProgression * 100);

  // Preload image
  useEffect(() => {
    const image = new Image();
    image.src = '/assetsLuckyVerse/LoadingScreen.webp';
    image.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  useEffect(() => {
    const animateProgress = () => {
      setDisplayProgress((prev) => {
        let target;

        if (appLoaded) {
          target = FINAL_PROGRESS;
        } else {
          target = Math.max(progress, gameProgress);
        }

        const nextValue = Math.min(prev + PROGRESS_STEP, target);

        if (nextValue < target) {
          animationRef.current = requestAnimationFrame(animateProgress);
        }

        return nextValue;
      });
    };

    animationRef.current = requestAnimationFrame(animateProgress);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [progress, gameProgress, appLoaded, unityLoadProgress]);

  useEffect(() => {
    if (telegram) {
      telegram.setHeaderColor('rgb(60, 157, 226)');
      telegram.setBackgroundColor('rgb(60, 157, 226)');
      window.scrollTo(0, 0);
      telegram.expand();
    }

    return () => {
      if (telegram) {
        telegram.setHeaderColor('#000000');
        telegram.setBackgroundColor('#000000');
      }
    };
  }, [telegram]);

  useEffect(() => {
    if (appLoaded && firstOnboarding) {
      setShowOnboarding(true);
    }
  }, [setShowOnboarding, appLoaded, firstOnboarding]);

  useEffect(() => {
    if (appLoaded && unityLoadProgress >= 100 && !firstOnboarding) {
      setFullLoaded(true);
    }
  }, [setFullLoaded, firstOnboarding, appLoaded, unityLoadProgress]);

  if (!imageLoaded) {
    return null;
  }

  return (
    <div className={styles.playScreen}>
      <img
        className={styles.image}
        style={
          isVerticalOrientation
            ? { height: '100dvh', width: 'auto' }
            : { width: '100dvw', height: 'auto' }
        }
        src='/assetsLuckyVerse/LoadingScreen.webp'
        alt='Game logo'
      />

      <div className={styles.container}>
        <div className={styles.progressContainer}>
          <div
            className={styles.progress}
            style={{
              width: `${Math.round(
                ((displayProgress + progress) / 2) * 0.8 +
                  unityLoadProgress * 0.2
              )}%`,
            }}
          />
        </div>
      </div>
      {showOnboarding && firstOnboarding && (
        <OnboardingLuckyVerse setFirstOnboarding={setFirstOnboarding} />
      )}
    </div>
  );
}

export default LoadingLuckyVerse;
