import { useEffect, useState, useCallback } from 'react';
import { formatPhoto, getAvatar } from '../api/avatar';

import { useUnity } from '../contexts/UnityContext';
import { useApp } from '../contexts/AppContext';

import GAMES_CONFIG from '../GamesConfig';

const PHOTO_STATUS = {
  empty: 'empty',
  no_id: 'no_id',
};

const CACHE_EXPIRATION = 7 * 24 * 60 * 60 * 1000; // 7 дней

const SDK_LoadAvatarByUserId = () => {
  const { isAppStarted, sendMessage, addEventListener, removeEventListener } =
    useUnity();
  const [pendingRequests, setPendingRequests] = useState([]);
  const { appId } = useApp();

  const CACHE_NAME = `UnityCache_${GAMES_CONFIG[appId].subtitle}_${GAMES_CONFIG[appId].title}_image`;

  // Функция для работы с кэшем
  const cacheManager = {
    async get(key) {
      try {
        const cache = await caches.open(CACHE_NAME);
        const response = await cache.match(key);

        if (!response) return null;

        const data = await response.json();

        // Проверяем срок годности кэша
        if (data.timestamp && Date.now() - data.timestamp > CACHE_EXPIRATION) {
          await cache.delete(key);
          return null;
        }

        return data.avatarUrl;
      } catch (error) {
        console.error('Cache read error:', error);
        return null;
      }
    },

    async set(key, avatarUrl) {
      try {
        const cache = await caches.open(CACHE_NAME);
        const data = {
          avatarUrl,
          timestamp: Date.now(),
        };

        // Создаем Response объект с нашими данными
        const response = new Response(JSON.stringify(data), {
          headers: { 'Content-Type': 'application/json' },
        });

        await cache.put(key, response);
      } catch (error) {
        console.error('Cache write error:', error);
      }
    },
  };

  // Функция для получения аватара с учетом кэша
  const fetchAvatarWithCache = async (userId) => {
    const cacheKey = `avatar-${userId}`;

    // Пробуем получить из кэша
    const cachedAvatar = await cacheManager.get(cacheKey);
    if (cachedAvatar) {
      return cachedAvatar;
    }

    // Если в кэше нет, делаем запрос
    const avatar = await getAvatar(userId);
    let avatarUrl = PHOTO_STATUS.empty;

    if (avatar?.photo_url) {
      avatarUrl = await formatPhoto(avatar.photo_url);
      // Сохраняем в кэш только если получили реальный URL
      if (avatarUrl !== PHOTO_STATUS.empty) {
        await cacheManager.set(cacheKey, avatarUrl);
      }
    }

    return avatarUrl;
  };

  // Обработчик для отправки сообщения
  const processRequest = useCallback(
    async (request) => {
      if (!isAppStarted || !request) return;

      const { id, avatarUrl } = request;

      sendMessage(
        'TeleLauncherSDK',
        'OnAvatarBase64Get',
        JSON.stringify({
          userId: id,
          avatarBase64: avatarUrl === PHOTO_STATUS.empty ? '' : avatarUrl,
        })
      );
    },
    [isAppStarted, sendMessage]
  );

  // Обработка очереди запросов
  useEffect(() => {
    if (pendingRequests.length > 0) {
      const currentRequest = pendingRequests[0];
      processRequest(currentRequest).then(() => {
        setPendingRequests((prev) => prev.slice(1));
      });
    }
  }, [pendingRequests, processRequest]);

  // Обработчик события LoadAvatarByUserId
  useEffect(() => {
    const handleLoadAvatarByUserId = async (message) => {
      if (!message) return;

      const userId = +message;
      const avatarUrl = await fetchAvatarWithCache(userId);

      setPendingRequests((prev) => [...prev, { id: userId, avatarUrl }]);
    };

    addEventListener('LoadAvatarByUserId', handleLoadAvatarByUserId);
    return () =>
      removeEventListener('LoadAvatarByUserId', handleLoadAvatarByUserId);
  }, [addEventListener, removeEventListener]);

  return null;
};

export default SDK_LoadAvatarByUserId;
