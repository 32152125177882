import axios from 'axios';
import { useEffect, useState } from 'react';

import { useApp } from '../contexts/AppContext';

import { createUser, getAuth, setPhoto } from '../api/auth';
import { formatPhoto } from '../api/avatar';
import { getBalance } from '../api/balance';
import { getSave } from '../api/saves';

import { sendGTMEvent } from '../utils/analytics';
import {
  cloudGetItem,
  cloudSetItem,
  localGetItem,
  localSetItem,
} from '../utils/cloudStorage';
import { GA4_KEY, STORAGE_KEY } from '../utils/constants';
import promoHandlerUtility from '../utils/promoHandlerUtility';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

const LOADING_STAGES = {
  INITIAL: { progress: 0, text: 'Initializing...' },
  AUTH: { progress: 20, text: 'Authenticating...' },
  AUTH_COMPLETE: { progress: 34, text: 'Authentication complete' },
  USER_REGISTER: { progress: 47, text: 'Registering user...' },
  USER_UPDATE: { progress: 52, text: 'Updating user data...' },
  BALANCE: { progress: 65, text: 'Fetching balance...' },
  SAVE_DATA: { progress: 87, text: 'Loading save data...' },
  UNITY_INIT: { progress: 96, text: 'Initializing game engine...' },
  // UNITY_LOAD: { progress: 90, text: 'Loading game assets...' },
  // SCRIPTS_LOAD: { progress: 95, text: 'Loading additional resources...' },
  COMPLETE: { progress: 98, text: 'Starting game...' },
};

export const useInitializeApp = (setFirstOnboarding) => {
  const {
    user,
    appId,
    isTelegram,
    setBalance,
    isExists,
    isInitialized,
    setIsInitialized,
    setAvatar,
    setSavedData,
    isNeedSave,
    isNeedBalance,
    setAllStartTime,
  } = useApp();

  const [currentStage, setCurrentStage] = useState(LOADING_STAGES.INITIAL);
  const getPhotoURL = async () => {
    if (user?.photo_url) {
      const photo = await formatPhoto(user.photo_url);
      return photo;
    }
    return `${process.env.REACT_APP_SERVICE_URL}/api/avatar/${user.id}`;
  };

  const initializeApp = async (
    retryAttempt = 0,
    country = '',
    countryCode = ''
  ) => {
    try {
      if (!isTelegram) {
        throw new Error('Telegram WebApp API is not available');
      }

      user.country = country;

      // Authentication
      let token = localGetItem('Token');
      setCurrentStage(LOADING_STAGES.AUTH);

      if (!token) {
        const authData = await getAuth(user);
        token = authData.token;
        localSetItem('Token', token, 3600 * 24);
      }
      setCurrentStage(LOADING_STAGES.AUTH_COMPLETE);

      // User registration
      setCurrentStage(LOADING_STAGES.USER_REGISTER);
      const urlParams = new URLSearchParams(window.location.search);
      let referer_id = urlParams.get('tgWebAppStartParam');
      if (!referer_id) {
        referer_id = null;
      }
      user.referer_id = referer_id;
      if (isExists === false) {
        if (setFirstOnboarding) {
          setFirstOnboarding(true);
        }
        await createUser(user);
      } else if (isExists === true && referer_id) {
        // Handle promo code for existing users
        promoHandlerUtility.handlePromoCode(referer_id, user?.id, true);
      }

      promoHandlerUtility.sendPromoCallback(user.id);

      if (user.photo_url) {
        setCurrentStage(LOADING_STAGES.USER_UPDATE);
        await setPhoto(user);
      }

      setCurrentStage(LOADING_STAGES.BALANCE);
      if (isNeedBalance) {
        const balanceData = await getBalance(user.id);
        setBalance(+balanceData.balance);
      } else {
        setBalance(0);
      }

      // Load save data
      setCurrentStage(LOADING_STAGES.SAVE_DATA);

      const photo_url = await getPhotoURL();
      setAvatar(photo_url);

      // Load saves
      if (isNeedSave) {
        try {
          const save = await cloudGetItem(STORAGE_KEY.SAVE_DATA);
          if (save !== null) {
            setSavedData(save);
          } else {
            const data = await getSave(user.id, appId);
            if (data?.data) {
              setSavedData(JSON.stringify(data.data));
              cloudSetItem(STORAGE_KEY.SAVE_DATA, JSON.stringify(data.data));
            } else {
              setSavedData('');
              cloudSetItem(STORAGE_KEY.SAVE_DATA, null);
            }
          }
        } catch (error) {
          setSavedData('');
        }
      } else {
        setSavedData('');
      }

      setCurrentStage(LOADING_STAGES.COMPLETE);
      user.country = countryCode.toLowerCase();
      setIsInitialized(true);
      setAllStartTime((prev) => ({
        ...prev,
        total_react: new Date().getTime(),
      }));
      sendGTMEvent(GA4_KEY.unity_initializate);
    } catch (error) {
      console.error(
        `Initialization attempt ${retryAttempt + 1} failed:`,
        error
      );

      if (retryAttempt < MAX_RETRIES) {
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        return initializeApp(retryAttempt + 1, country, countryCode);
      }

      if (retryAttempt === MAX_RETRIES) {
        setCurrentStage(LOADING_STAGES.BALANCE);
        setBalance(0);
        setCurrentStage(LOADING_STAGES.SAVE_DATA);
        if (isNeedSave) {
          try {
            const save = await cloudGetItem(STORAGE_KEY.SAVE_DATA);
            if (save !== null) {
              setSavedData(save);
            } else {
              setSavedData('');
              cloudSetItem(STORAGE_KEY.SAVE_DATA, null);
            }
          } catch (error) {
            setSavedData('');
          }
        } else {
          setSavedData('');
        }
        setCurrentStage(LOADING_STAGES.COMPLETE);
        user.country = countryCode.toLowerCase();
        setIsInitialized(true);
        setAllStartTime((prev) => ({
          ...prev,
          total_react: new Date().getTime(),
        }));
        sendGTMEvent(GA4_KEY.unity_initializate);
      }
    }
  };

  const getAPIInfo = async () => {
    try {
      const response = await axios.get('https://ipapi.co/json/');
      const { country_name, country_code } = response.data;
      return { country_name, country_code };
    } catch (error) {
      console.error(`Error: ${error.message}`);
      return { country_name: '', country_code: '' };
    }
  };

  useEffect(() => {
    if (!isInitialized) {
      const init = async () => {
        const { country_name, country_code } = await getAPIInfo();
        initializeApp(0, country_name, country_code);
      };

      init();
    }
  }, []);

  return {
    progress: currentStage.progress,
    loadingText: currentStage.text,
  };
};
