import { ThemeProvider } from '@emotion/react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { THEME } from '@tonconnect/ui';

import { AppProvider, useApp } from './contexts/AppContext';
import { UnityProvider } from './contexts/UnityContext';

import GAMES_CONFIG from './GamesConfig';
import { GAMES_ID } from './utils/constants';

import GamePage from './pages/GamePage/GamePage';
import Loading from './components/Loading/Loading';
import LoadingLuckyVerse from './gamesSrc/luckyVerse/componentsLuckyVerse/LoadingLuckyVerse/LoadingLuckyVerse';

const theme = {
  colors: {
    primary: '#6200ea',
    secondary: '#03dac6',
    background: '#f0f0f0',
    text: '#000000',
  },
  fonts: {
    body: 'Arial, sans-serif',
  },
};

function AppContent() {
  const { appId, user, fullLoaded } = useApp();

  const manifestUrl = `${process.env.REACT_APP_SERVICE_URL}/tonconnect-manifest.json?app_id=${appId}`;
  const twaReturnUrl = `${GAMES_CONFIG[appId].telegramUrl}?startapp=${user.id}`;

  return (
    <TonConnectUIProvider
      manifestUrl={manifestUrl}
      actionsConfiguration={{
        twaReturnUrl: twaReturnUrl,
      }}
      uiPreferences={{ theme: THEME.LIGHT }}
    >
      {!fullLoaded &&
        (appId === GAMES_ID.LUCKY_VERSE ||
        appId === GAMES_ID.LUCKY_VERSE_TEST ? (
          <LoadingLuckyVerse />
        ) : (
          <Loading />
        ))}
      <GamePage />
    </TonConnectUIProvider>
  );
}

const App = () => {
  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <UnityProvider>
          <AppContent />
        </UnityProvider>
      </ThemeProvider>
    </AppProvider>
  );
};

export default App;
