import React, { createContext, useContext, useEffect, useState } from 'react';
import { useUnityContext } from 'react-unity-webgl';

import { useDevicePixelRatio } from '../hooks/useDevicePixelRatio';

import { localGetItem, localSetItem } from '../utils/cloudStorage';
import { STORAGE_KEY } from '../utils/constants';

import { useApp } from './AppContext';

import GAMES_CONFIG from '../GamesConfig';

import SDK_AppStarted from '../sdk/SDK_AppStarted';
import SDK_SetUnityLoadProgress from '../sdk/SDK_SetUnityLoadProgress';

const UnityContext = createContext(null);

export const useUnity = () => {
  const context = useContext(UnityContext);
  if (!context) {
    throw new Error('useUnity должен использоваться внутри UnityProvider');
  }
  return context;
};

export const UnityProvider = ({ children }) => {
  const {
    appId,
    user,
    avatar,
    balance,
    savedData,
    isInitialized,
    telegram,
    setAppLoaded,
  } = useApp();
  const devicePixelRatio = useDevicePixelRatio();

  const [isAppStarted, setIsAppStarted] = useState(false);
  const [unityLoadProgress, setUnityLoadProgress] = useState(0);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  const handleCacheControl = (url) => {
    const CACHE_EXTENSIONS = [
      '.data',
      '.loader',
      '.framework',
      '.wasm',
      '.js',
      '.json',
      '.unity3d',
      '.bundle',
      '.assets',
      '.resource',
      '.resS',
      '.config',
      '.mem',
      '.symbols.json',
      '.jsgz',
      '.datagz',
      '.memgz',
      '.unity',
      '.unityweb',
      '.br',
    ];

    const shouldCache = CACHE_EXTENSIONS.some((ext) =>
      url.toLowerCase().endsWith(ext)
    );

    if (shouldCache) {
      const needClear = localGetItem(
        `${STORAGE_KEY.NEED_CLEAR_UNITY_CACHE}${appId}`
      );
      return needClear ? 'must-revalidate' : 'immutable';
    }
    return 'no-store';
  };

  const unityContext = useUnityContext({
    ...GAMES_CONFIG[appId].gameUrls,
    autoSyncPersistentDataPath: true,
    webglContextAttributes: {
      alpha: false,
      antialias: false,
      powerPreference: 'high-performance',
      preserveDrawingBuffer: false,
      premultipliedAlpha: false,
      desynchronized: true,
      failIfMajorPerformanceCaveat: false,
    },
    productVersion: '1.0',
    productName: GAMES_CONFIG[appId].title,
    companyName: GAMES_CONFIG[appId].subtitle,
    devicePixelRatio: window.devicePixelRatio,
    cacheControl: handleCacheControl,
  });

  const { addEventListener, removeEventListener, sendMessage } = unityContext;

  SDK_AppStarted(setIsAppStarted, addEventListener, removeEventListener);

  // Отслеживаем прогресс загрузки Unity
  useEffect(() => {
    if (unityLoadProgress === 100 && !isLoadingComplete) {
      setIsLoadingComplete(true);
      const needClear = localGetItem(
        `${STORAGE_KEY.NEED_CLEAR_UNITY_CACHE}${appId}`
      );

      if (needClear) {
        // Сбрасываем флаг только после полной загрузки
        localSetItem(`${STORAGE_KEY.NEED_CLEAR_UNITY_CACHE}${appId}`, false);
        console.log('Unity cache has been updated and flag has been reset');
      }
    }
  }, [unityLoadProgress, isLoadingComplete, appId]);

  useEffect(() => {
    if (isAppStarted && isInitialized) {
      let token = localGetItem('Token');

      const safeAreaInset = devicePixelRatio
        ? Object.fromEntries(
            Object.entries(telegram.safeAreaInset).map(([key, value]) => [
              key,
              value * devicePixelRatio,
            ])
          )
        : telegram.safeAreaInset;

      const contentSafeAreaInset = devicePixelRatio
        ? Object.fromEntries(
            Object.entries(telegram.contentSafeAreaInset).map(
              ([key, value]) => [key, value * devicePixelRatio]
            )
          )
        : telegram.contentSafeAreaInset;

      sendMessage(
        'TeleLauncherSDK',
        'InitializeApp',
        JSON.stringify({
          appId,
          app_url: GAMES_CONFIG[appId].assertsUrl || '',
          user: {
            ...user,
            user_token: token || '',
            reffer_id: user.referer_id || '',
            photo_url: avatar,
          },
        })
      );

      sendMessage('TeleLauncherSDK', 'OnGetBalance', +balance || 0);
      sendMessage('TeleLauncherSDK', 'OnSavesLoad', savedData || '');

      sendMessage(
        'TeleLauncherSDK',
        'OnSafeAreaUpdated',
        JSON.stringify({
          safeAreaInset: safeAreaInset,
          contentSafeAreaInset: contentSafeAreaInset,
        })
      );
      setAppLoaded(true);
    }
  }, [isAppStarted, isInitialized]);

  SDK_SetUnityLoadProgress(
    sendMessage,
    addEventListener,
    removeEventListener,
    setUnityLoadProgress,
    unityLoadProgress
  );

  return (
    <UnityContext.Provider
      value={{
        ...unityContext,
        isAppStarted,
        unityLoadProgress,
      }}
    >
      {children}
    </UnityContext.Provider>
  );
};
