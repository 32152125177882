import { useState, useEffect } from 'react';
import { cloudGetItem } from '../utils/cloudStorage';
import { STORAGE_KEY } from '../utils/constants';
import { useUnity } from '../contexts/UnityContext';
import { checkTransactionStatus } from '../utils/paymentsUtils';

const useCheckIsHavePayment = (
  // isAppStarted,
  // sendMessage,
  setIsPopupVisible,
  setIsLoading,
  setPaymentProduct
) => {
  const { isAppStarted, sendMessage } = useUnity();
  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (!isAppStarted) return;

      try {
        const item = await cloudGetItem(STORAGE_KEY.PAYMENT_HASH);

        if (item?.hash && item?.paymentProduct) {
          setIsPopupVisible(true);
          setIsLoading(true);
          setPaymentProduct(item.paymentProduct);

          await checkTransactionStatus(
            item.hash,
            item.paymentProduct,
            setPaymentProduct,
            sendMessage,
            setIsLoading,
            setIsPopupVisible
          );
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
        setIsLoading(false);
      }
    };

    checkPaymentStatus();
  }, [isAppStarted, sendMessage, STORAGE_KEY]);

  return null;
};

export default useCheckIsHavePayment;
