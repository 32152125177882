import { useEffect, useState } from 'react';

import { useUnity } from '../contexts/UnityContext';

import { getPaymentProducts } from '../api/payments';

import { sendGTMEvent } from '../utils/analytics';
import { GA4_KEY } from '../utils/constants';

const SDK_GetProducts = () => {
  const { isAppStarted, sendMessage, addEventListener, removeEventListener } =
    useUnity();
  const [products, setProducts] = useState(null);

  useEffect(() => {
    if (!!isAppStarted && !!products) {
      sendMessage('TeleLauncherSDK', 'OnProductsGet', JSON.stringify(products));
      setProducts(null);
      sendGTMEvent(GA4_KEY.unity_get_products);
    }
  }, [isAppStarted, products, sendMessage]);

  useEffect(() => {
    const handleGetProducts = async (message) => {
      if (message) {
        const messageObj = JSON.parse(message);
        if (!!messageObj) {
          setProducts(null);
          const data = await getPaymentProducts(messageObj.products);
          if (data) {
            setProducts(data);
          }
        }
      }
    };
    addEventListener('GetProducts', handleGetProducts);
    return () => removeEventListener('GetProducts', handleGetProducts);
  }, [addEventListener, removeEventListener]);
};

export default SDK_GetProducts;
