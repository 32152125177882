import { useEffect, useState } from 'react';
import useAdsgram from '../hooks/useAdsgram';
import { useUnity } from '../contexts/UnityContext';

const SDK_ShowRewardedAd = () => {
  const showAd = useAdsgram();
  const { isAppStarted, sendMessage, addEventListener, removeEventListener } =
    useUnity();
  const [rewardResult, setRewardResult] = useState(null);

  useEffect(() => {
    if (!!isAppStarted && !!rewardResult) {
      sendMessage(
        'TeleLauncherSDK',
        'OnRewardedAdResult',
        JSON.stringify(rewardResult)
      );
      setRewardResult(null);
    }
  }, [isAppStarted, rewardResult, sendMessage]);

  useEffect(() => {
    const handleShowRewardedAd = async (message) => {
      const result = await showAd();
      setRewardResult(result);
    };
    addEventListener('ShowRewardedAd', handleShowRewardedAd);
    return () => removeEventListener('ShowRewardedAd', handleShowRewardedAd);
  }, [addEventListener, removeEventListener, showAd]);
};

export default SDK_ShowRewardedAd;
