import { useEffect } from 'react';

import { useApp } from '../contexts/AppContext';
import { useUnity } from '../contexts/UnityContext';

import { GA4_KEY } from '../utils/constants';
import { sendGTMEvent } from '../utils/analytics';

import GAMES_CONFIG from '../GamesConfig';

const SDK_SaveRefLinkToClipboard = () => {
  const { appId, user } = useApp();
  const { addEventListener, removeEventListener } = useUnity();

  useEffect(() => {
    const handleSaveRefLinkToClipboard = async () => {
      const tempInput = document.createElement('input');
      tempInput.value = `${GAMES_CONFIG[appId].telegramUrl}/app?startapp=${user.id}`;
      document.body.appendChild(tempInput);
      tempInput.select();

      try {
        const success = document.execCommand('copy');
        if (success) {
          alert(
            'Link copied to clipboard. You can now paste it into the desired chat.'
          );
        } else {
          alert('Failed to copy link. Please copy it manually.');
        }
      } catch (err) {
        console.error('Error copying link: ', err);
        alert(
          'An error occurred while copying the link. Please copy it manually.'
        );
      } finally {
        document.body.removeChild(tempInput);
        sendGTMEvent(GA4_KEY.unity_save_link_to_clipboard);
      }
    };

    addEventListener('SaveRefLinkToClipboard', handleSaveRefLinkToClipboard);
    return () =>
      removeEventListener(
        'SaveRefLinkToClipboard',
        handleSaveRefLinkToClipboard
      );
  }, [addEventListener, removeEventListener, appId]);
};

export default SDK_SaveRefLinkToClipboard;
