const BASE_URL = process.env.REACT_APP_SERVICE_URL;

export function sendAnalyticsEvent(event, payload) {
  const token = JSON.parse(localStorage.getItem('Token'));
  const searchParams = new URLSearchParams(window.location.search);
  const appId = searchParams.get('app_id');
  const headers = {
    Authorization: token?.value || '',
    'Content-Type': 'application/json',
  };

  const request = new Request(`${BASE_URL}/api/analytics-event`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      event,
      payload: {
        app_id: appId,
        start_time: payload,
      },
    }),
  });

  fetch(request).catch((error) => {
    console.error('Analytics event error:', error);
  });

  return;
}
