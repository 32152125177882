import { useEffect, useState } from 'react';

import { useApp } from '../contexts/AppContext';

import { getBalance } from '../api/balance';

import { useUnity } from '../contexts/UnityContext';

import { GA4_KEY } from '../utils/constants';

import { sendGTMEvent } from '../utils/analytics';

const SDK_GetBalance = (
) => {
  const { user, balance, setBalance } = useApp();
  const { isAppStarted, sendMessage, addEventListener, removeEventListener } =
    useUnity();

  const [isLoadedBalance, setIsLoadedBalance] = useState(false);

  useEffect(() => {
    if (!!isAppStarted && !!isLoadedBalance) {
      sendMessage('TeleLauncherSDK', 'OnGetBalance', +balance);
      setIsLoadedBalance(false);
      sendGTMEvent(GA4_KEY.unity_get_balance);
    }
  }, [isAppStarted, balance, isLoadedBalance, sendMessage]);

  useEffect(() => {
    const handleGetBalance = async () => {
      const data = await getBalance(user.id);

      if (data) {
        setBalance(+data.balance);
        setIsLoadedBalance(true);
      }
    };

    addEventListener('GetBalance', handleGetBalance);
    return () => removeEventListener('GetBalance', handleGetBalance);
  }, [addEventListener, removeEventListener, setBalance]);
};

export default SDK_GetBalance;
