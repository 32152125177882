import axios from 'axios';

import makeRequest from './apiConfig';

const WORKER_URL = 'https://tme-cors-worker.alex-074.workers.dev/?url=';

const avatarEndpoints = {
  getAvatar: '/api/avatar-url',
};

export const getAvatar = async (id) => {
  try {
    const response = await makeRequest({
      method: 'GET',
      endpoint: `${avatarEndpoints.getAvatar}/${+id}`,
    });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    console.error('Failed to get avatar:', error);
    return null;
  }
};

export const formatPhoto = async (photo_url) => {
  const response = await axios.get(
    `${WORKER_URL}${encodeURIComponent(photo_url)}`,
    {
      responseType: 'blob',
    }
  );
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(response.data);
  });
};
