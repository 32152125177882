import React, { useEffect, useState, useRef } from 'react';

import { useApp } from '../../contexts/AppContext';
import { useUnity } from '../../contexts/UnityContext';

import GAMES_CONFIG from '../../GamesConfig';

import { useInitializeApp } from '../../hooks/useInitializeApp';

import styles from './Loading.module.css';

const PROGRESS_STEP = 0.5;
const FINAL_PROGRESS = 100;

function Loading() {
  const { appId, telegram, appLoaded, setFullLoaded } = useApp();
  const { loadingProgression, unityLoadProgress } = useUnity();

  const [displayProgress, setDisplayProgress] = useState(0);

  const animationRef = useRef(null);

  const { progress, loadingText } = useInitializeApp();

  const gameProgress = Math.floor(loadingProgression * 100);

  useEffect(() => {
    const animateProgress = () => {
      setDisplayProgress((prev) => {
        let target;

        if (appLoaded) {
          target = FINAL_PROGRESS;
        } else {
          target = Math.max(progress, gameProgress);
        }

        const nextValue = Math.min(prev + PROGRESS_STEP, target);

        if (nextValue < target) {
          animationRef.current = requestAnimationFrame(animateProgress);
        }

        return nextValue;
      });
    };

    animationRef.current = requestAnimationFrame(animateProgress);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [progress, gameProgress, appLoaded, unityLoadProgress]);

  useEffect(() => {
    if (telegram) {
      telegram.setHeaderColor('rgb(60, 157, 226)');
      telegram.setBackgroundColor('rgb(60, 157, 226)');
      window.scrollTo(0, 0);
      telegram.expand();
    }

    return () => {
      if (telegram) {
        telegram.setHeaderColor('#000000');
        telegram.setBackgroundColor('#000000');
      }
    };
  }, [telegram]);

  useEffect(() => {
    if (appLoaded && unityLoadProgress >= 100) {
      setTimeout(() => {
        setFullLoaded(true);
      }, 400);
    }
  }, [displayProgress, setFullLoaded, appLoaded, unityLoadProgress]);

  return (
    <div className={styles.playScreen}>
      <div className={styles.version}>v1.0.0</div>

      <img
        className={styles.image}
        src={GAMES_CONFIG[appId].logo}
        alt='Логотип игры'
      />

      {GAMES_CONFIG[appId].title && (
        <h1 className={styles.title}>{GAMES_CONFIG[appId].title}</h1>
      )}

      {GAMES_CONFIG[appId].subtitle && (
        <p className={styles.subtitle}>{GAMES_CONFIG[appId].subtitle}</p>
      )}

      <div className={styles.loaderWrapper}>
        <div className={styles.loaderContainer}>
          <div
            className={styles.loaderBar}
            style={{
              // width: `${Math.round((progress + displayProgress) / 2)}%`,
              width: `${Math.round(progress * 0.8 + unityLoadProgress * 0.2)}%`,
            }}
          />
          <div className={styles.loaderProgress}>
            {/* {Math.round((progress + displayProgress) / 2)}% */}
            {Math.round(progress * 0.8 + unityLoadProgress * 0.2)}%
          </div>
        </div>
        <div className={styles.loaderText}>{loadingText}</div>
      </div>
    </div>
  );
}

export default Loading;
