import {
  TonConnectButton,
  useTonAddress,
  useTonConnectUI,
} from '@tonconnect/ui-react';
import React, { useEffect, useState } from 'react';

import { useApp } from '../../contexts/AppContext';

import { sendGTMEvent } from '../../utils/analytics';
import { cloudGetItem } from '../../utils/cloudStorage';
import { GA4_KEY, GAMES_CURRENCIES, STORAGE_KEY } from '../../utils/constants';
import { getStarsPayment, getTonPayment } from '../../utils/paymentsUtils';

import GAMES_CONFIG from '../../GamesConfig';

import Loader from '../Loader/Loader';

import './Popup.css';

const Popup = ({
  isVisible,
  setIsVisible,
  sendMessage,
  paymentProduct,
  setPaymentProduct,
  isLoading,
  setIsLoading,
  showConnectWallet,
  setShowConnectWallet,
}) => {
  const { appId, user, isTelegram, telegram } = useApp();

  const [tonConnectUI] = useTonConnectUI();
  const userAddress = useTonAddress();
  const tonAddress = useTonAddress(true);

  //нужен рефактор
  function localRemoveItem(key) {
    localStorage.removeItem(key);
  }

  function localItem(key, callback = null) {
    const itemString = localStorage.getItem(key);
    if (!itemString) {
      return null;
    }
    const item = JSON.parse(itemString);
    const now = Date.now();
    if (item.expiry && now > item.expiry) {
      if (callback) {
        callback(item.value);
      }
      localRemoveItem(key);
      return null;
    }
    return item.value;
  }

  const [
    token,
    // setToken
  ] = useState(localItem('Token'));
  //

  const togglePopup = () => {
    if (isTelegram) {
      telegram.HapticFeedback.impactOccurred('medium');
    }
    cloudGetItem(STORAGE_KEY.PAYMENT_HASH).then(async (item) => {
      if (!item) {
        setIsVisible(!isVisible);
        sendMessage(
          'TeleLauncherSDK',
          'OnPurchaseResult',
          JSON.stringify({
            product_id: paymentProduct.productId,
            ...(paymentProduct.payload && { payload: paymentProduct.payload }),
            status: 1,
            hash: '',
          })
        );
      }
    });
  };

  useEffect(() => {
    if (tonAddress && token) {
      const userId = user.id;
      if (userId) {
        fetch(`${process.env.REACT_APP_SERVICE_URL}/api/connect-wallet`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: userId, ton_address: tonAddress }),
        });
      }
    }
  }, [tonAddress, token]);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isVisible]);

  useEffect(() => {
    if (userAddress && showConnectWallet) {
      setShowConnectWallet(false);
      getTonPayment(
        paymentProduct,
        setPaymentProduct,
        sendMessage,
        tonConnectUI,
        setIsLoading,
        setIsVisible
      );
    }
  }, [
    userAddress,
    showConnectWallet,
    setShowConnectWallet,
    paymentProduct,
    setPaymentProduct,
    sendMessage,
    tonConnectUI,
    setIsLoading,
    setIsVisible,
  ]);

  const paymentRequest = async (currency = '') => {
    try {
      if (currency === 'STARS') {
        getStarsPayment(
          paymentProduct,
          setPaymentProduct,
          sendMessage,
          setIsLoading,
          setIsVisible,
          currency
        );
      } else {
        if (!userAddress) {
          setShowConnectWallet(true);
          return;
        }
        getTonPayment(
          paymentProduct,
          setPaymentProduct,
          sendMessage,
          tonConnectUI,
          setIsLoading,
          setIsVisible,
          currency
        );
      }
    } catch (error) {
      console.error('Error creating order:', error);
      alert('Failed to create order. Please try again.');
    }
  };

  return (
    <div className={`popup ${isVisible ? 'visible' : ''}`}>
      {showConnectWallet ? (
        <>
          <div className='popup-header'>
            <button className='close-popup' onClick={togglePopup}>
              &times;
            </button>
            <button
              className='back-popup'
              onClick={() => {
                if (isTelegram) {
                  telegram.HapticFeedback.impactOccurred('medium');
                }
                setShowConnectWallet(false);
              }}
            >
              &#8592;
            </button>
          </div>
          <div className='popup-content'>
            <div className='connect-wallet-container'>
              <p>Please connect your wallet to proceed with TON payment</p>
              <TonConnectButton />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='popup-header'>
            {userAddress && (
              <div className='wallet-button'>
                <TonConnectButton />
              </div>
            )}
            <button className='close-popup' onClick={togglePopup}>
              &times;
            </button>
            <div className='popup-title'>Choose Payment Method</div>
          </div>

          <div className='popup-content'>
            {isLoading && <Loader message='Processing payment...' />}
            {!isLoading && (
              <>
                {GAMES_CONFIG[appId].paymentCurrencies.map((currency) => {
                  return (
                    <button
                      className={`popup-button
                          ${
                            GAMES_CURRENCIES[currency].className
                              ? `${GAMES_CURRENCIES[currency].className}-button`
                              : ''
                          }
                          `}
                      disabled={GAMES_CONFIG[appId].disabledCurrencies.includes(
                        GAMES_CURRENCIES[currency].code
                      )}
                      key={GAMES_CURRENCIES[currency].name}
                      onClick={() => {
                        if (isTelegram) {
                          telegram.HapticFeedback.impactOccurred('medium');
                        }
                        paymentRequest(GAMES_CURRENCIES[currency].code);
                        sendGTMEvent(
                          GA4_KEY.payment(paymentProduct.productId, false)
                        );
                      }}
                    >
                      <span>Buy with {GAMES_CURRENCIES[currency].name}</span>

                      {GAMES_CURRENCIES[currency].symbol && (
                        <div className='symbol'>
                          {GAMES_CURRENCIES[currency].symbol}
                        </div>
                      )}
                    </button>
                  );
                })}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Popup;
