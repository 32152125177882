import { getSave } from '../api/saves';

import {
  cloudGetItem,
  cloudSetItem,
  localGetItem,
  localSetItem,
} from './cloudStorage';

import { sendGTMEvent } from './analytics';

import { GA4_KEY, STORAGE_KEY } from './constants';
import { clearAppCaches } from './clearAppCaches';

export const handleUnityCache = async (allCache, appId, id, isNeedSave) => {
  try {
    const unityCacheLocal = localGetItem(`${STORAGE_KEY.UNITY_CACHE}${appId}`);

    if (!allCache || !unityCacheLocal || unityCacheLocal !== allCache.etag) {
      await clearAppCaches(appId);
      localSetItem(`${STORAGE_KEY.NEED_CLEAR_UNITY_CACHE}${appId}`, true);
      if (allCache?.etag) {
        localSetItem(`${STORAGE_KEY.UNITY_CACHE}${appId}`, allCache.etag);
      }
      sendGTMEvent(GA4_KEY.unity_update);
    } else {
      localSetItem(`${STORAGE_KEY.NEED_CLEAR_UNITY_CACHE}${appId}`, false);
    }

    if (isNeedSave) {
      const savedCache = await cloudGetItem(STORAGE_KEY.SAVE_CACHE);
      if (savedCache === allCache.save_hashsum) {
        return;
      }

      const data = await getSave(id, appId);

      if (data?.data) {
        await Promise.all([
          cloudSetItem(STORAGE_KEY.SAVE_DATA, JSON.stringify(data.data)),
          cloudSetItem(STORAGE_KEY.SAVE_CACHE, allCache.save_hashsum),
        ]);
      }
    }
  } catch (error) {
    console.error('Error while processing Unity cache:', error);
    await clearAppCaches(appId);
  }
};
