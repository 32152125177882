import { useState, useEffect } from 'react';

const useDeviceOrientation = () => {
  const [isVerticalOrientation, setIsVerticalOrientation] = useState(false);

  useEffect(() => {
    const handleSizeChange = () => {
      setIsVerticalOrientation(window.innerHeight > window.innerWidth);
    };

    handleSizeChange();

    window.addEventListener('orientationchange', handleSizeChange);
    window.addEventListener('resize', handleSizeChange);

    return () => {
      window.removeEventListener('orientationchange', handleSizeChange);
      window.removeEventListener('resize', handleSizeChange);
    };
  }, []);

  return isVerticalOrientation;
};

export default useDeviceOrientation;
