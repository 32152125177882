import { useEffect } from 'react';

import { useApp } from '../contexts/AppContext';
import { useUnity } from '../contexts/UnityContext';

import { setSave } from '../api/saves';

import { cloudSetItem } from '../utils/cloudStorage';
import { GA4_KEY, STORAGE_KEY } from '../utils/constants';
import { sendGTMEvent } from '../utils/analytics';

const SDK_SaveData = () => {
  const { appId, user, isNeedSave } = useApp();
  const { addEventListener, removeEventListener } = useUnity();

  useEffect(() => {
    const handleGetSave = async (message) => {
      if (message) {
        if (isNeedSave) {
          cloudSetItem(STORAGE_KEY.SAVE_DATA, message);
          await setSave(user.id, appId, JSON.parse(message));
          sendGTMEvent(GA4_KEY.unity_save_data);
        }
      }
    };
    addEventListener('SaveData', handleGetSave);
    return () => removeEventListener('SaveData', handleGetSave);
  }, [addEventListener, removeEventListener, appId]);
};

export default SDK_SaveData;
