import { useEffect } from 'react';

import { useUnity } from '../contexts/UnityContext';

import { useApp } from '../contexts/AppContext';

const useSafeAreaChanged = () => {
  const { sendMessage } = useUnity();
  const { telegram } = useApp();

  useEffect(() => {
    const handleAreaChanged = () => {
      const safeAreaInset = devicePixelRatio
        ? Object.fromEntries(
            Object.entries(telegram.safeAreaInset).map(([key, value]) => [
              key,
              value * devicePixelRatio,
            ])
          )
        : telegram.safeAreaInset;

      const contentSafeAreaInset = devicePixelRatio
        ? Object.fromEntries(
            Object.entries(telegram.contentSafeAreaInset).map(
              ([key, value]) => [key, value * devicePixelRatio]
            )
          )
        : telegram.contentSafeAreaInset;

      sendMessage(
        'TeleLauncherSDK',
        'OnSafeAreaUpdated',
        JSON.stringify({
          contentSafeAreaInset,
          safeAreaInset,
        })
      );
    };

    telegram.onEvent('safeAreaChanged', handleAreaChanged);
    telegram.onEvent('contentSafeAreaChanged', handleAreaChanged);

    return () => {
      telegram.offEvent('safeAreaChanged', handleAreaChanged);
      telegram.offEvent('contentSafeAreaChanged', handleAreaChanged);
    };
  }, [sendMessage]);
};

export default useSafeAreaChanged;
