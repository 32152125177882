import { useEffect, useRef, useState } from 'react';

import { useUnity } from '../contexts/UnityContext';

const SDK_OnAppFocusChanged = () => {
  const [isTelegramAvailable, setIsTelegramAvailable] = useState(true);
  const { sendMessage } = useUnity();
  const prevAvailableRef = useRef(true);

  useEffect(() => {
    if (prevAvailableRef.current !== isTelegramAvailable) {
      sendMessage(
        'TeleLauncherSDK',
        'OnAppFocusChanged',
        isTelegramAvailable ? 1 : 0
      );
      prevAvailableRef.current = isTelegramAvailable;
    }
  }, [isTelegramAvailable]);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.onEvent('activated', () => {
        setIsTelegramAvailable(true);
      });
      window.Telegram.WebApp.onEvent('deactivated', () => {
        setIsTelegramAvailable(false);
      });

      return () => {
        window.Telegram.WebApp.offEvent('activated');
        window.Telegram.WebApp.offEvent('deactivated');
      };
    }
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setIsTelegramAvailable(true);
      } else {
        setIsTelegramAvailable(false);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

export default SDK_OnAppFocusChanged;
