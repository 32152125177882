import { useEffect } from 'react';

import { useUnity } from '../contexts/UnityContext';

import HapticSDK from './HapticSDK';

const SDK_HapticTrigger = () => {
  const { addEventListener, removeEventListener } = useUnity();

  useEffect(() => {
    addEventListener(
      'HapticTrigger',
      (message) => message && HapticSDK.trigger(message)
    );

    return () => {
      removeEventListener(
        'HapticTrigger',
        (message) => message && HapticSDK.trigger(message)
      );
    };
  }, [addEventListener, removeEventListener]);
};

export default SDK_HapticTrigger;
