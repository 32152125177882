import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import ReactGA from 'react-ga4';

import { useCheckCaches } from '../hooks/useCheckCaches';
import { useOrientationLock } from '../hooks/useOrientationLock';

import { checkIsUserExists } from '../api/exists';
import { getGamesUrls } from '../api/gamesUrls';

import { DEFAULT_USER } from '../utils/constants';

import GAMES_CONFIG from '../GamesConfig';

const AppContext = createContext();

// Провайдер контекста
export function AppProvider({ children }) {
  const searchParams = new URLSearchParams(window.location.search);
  const appId = +searchParams.get('app_id') || 1;
  const isNeedSave = GAMES_CONFIG[appId].backendFeatures.save;
  const isNeedBalance = GAMES_CONFIG[appId].backendFeatures.balance;

  const telegram = window.Telegram?.WebApp;
  const user = window.Telegram?.WebApp?.initDataUnsafe?.user || DEFAULT_USER;
  const isTelegram = telegram?.initData ? true : false;

  const [balance, setBalance] = useState(null);
  const [isExists, setIsExists] = useState();
  const [isCacheCleared, setIsCacheCleared] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [savedData, setSavedData] = useState(null);
  const [appLoaded, setAppLoaded] = useState(false);
  const [fullLoaded, setFullLoaded] = useState(false);

  const [allStartTime, setAllStartTime] = useState({});

  useOrientationLock(appId, telegram, isTelegram);

  useEffect(() => {
    setAllStartTime((prev) => ({ ...prev, start: new Date().getTime() }));
    const getIsExists = async () => {
      const { exists } = await checkIsUserExists(user.id);
      if (typeof GAMES_CONFIG[appId].gameUrls === 'string') {
        const actualGameUrls = await getGamesUrls(GAMES_CONFIG[appId].gameUrls);
        GAMES_CONFIG[appId].gameUrls = actualGameUrls;
      }
      setIsExists(exists);
    };

    getIsExists();
  }, []);

  useCheckCaches(isExists, appId, user, setIsCacheCleared, isNeedSave);

  useEffect(() => {
    const initTelegram = async () => {
      if (isTelegram) {
        if (GAMES_CONFIG[appId].fullScreen && +telegram.version >= 8) {
          try {
            telegram.requestFullscreen();
          } catch (error) {
            console.error('Error requesting fullscreen:', error);
          }
        }

        telegram.enableClosingConfirmation();
        if (appId !== 1) {
          telegram.setHeaderColor('rgb(60, 157, 226)');
          telegram.setBackgroundColor('rgb(60, 157, 226)');
        }
        window.scrollTo(0, 0);
        telegram.expand();
      }
    };

    initTelegram();
  }, [appId]);

  useEffect(() => {
    const ga_key = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
    if (ga_key) {
      ReactGA.initialize(ga_key);
      ReactGA.send('loading');
    }
  }, []);

  const value = useMemo(
    () => ({
      telegram,
      appId,
      user,
      isTelegram,
      isNeedBalance,
      isNeedSave,
      isExists,
      balance,
      setBalance,
      isInitialized,
      setIsInitialized,
      avatar,
      setAvatar,
      savedData,
      setSavedData,
      appLoaded,
      setAppLoaded,
      fullLoaded,
      setFullLoaded,
      allStartTime,
      setAllStartTime,
    }),
    [
      telegram,
      appId,
      user,
      isTelegram,
      balance,
      isExists,
      isInitialized,
      avatar,
      savedData,
      appLoaded,
      isNeedBalance,
      isNeedSave,
      fullLoaded,
      allStartTime,
    ]
  );

  return (
    <AppContext.Provider value={value}>
      {isExists !== undefined &&
        isExists !== null &&
        isCacheCleared &&
        children}
    </AppContext.Provider>
  );
}

export function useApp() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
}
