import { useEffect, useState } from 'react';

import { useUnity } from '../contexts/UnityContext';

import { getLeaderboard } from '../api/leaderboard';

import { sendGTMEvent } from '../utils/analytics';
import { GA4_KEY } from '../utils/constants';

const SDK_GetLeaderboardData = () => {
  const [leaderboardData, setLeaderboardData] = useState(null);
  const { addEventListener, removeEventListener, isAppStarted, sendMessage } =
    useUnity();

  useEffect(() => {
    if (!!isAppStarted && !!leaderboardData) {
      sendMessage(
        'TeleLauncherSDK',
        'LoadLeaderboardData',
        JSON.stringify(leaderboardData)
      );
      setLeaderboardData(null);
      sendGTMEvent(GA4_KEY.unity_get_leaderboard);
    }
  }, [isAppStarted, leaderboardData, sendMessage]);

  useEffect(() => {
    const handleGetLeaderboardData = async () => {
      const data = await getLeaderboard();
      if (data) {
        setLeaderboardData(data);
      }
    };

    addEventListener('GetLeaderboardData', handleGetLeaderboardData);
    return () =>
      removeEventListener('GetLeaderboardData', handleGetLeaderboardData);
  }, [addEventListener, removeEventListener]);
};

export default SDK_GetLeaderboardData;
