import { useEffect } from 'react';

import { useUnity } from '../contexts/UnityContext';

import { useWakeLock } from '../hooks/useWakeLock';

const SDK_SetScreenCanGoSleep = () => {
  const { addEventListener, removeEventListener } = useUnity();
  const { enable, disable, isSupported } = useWakeLock();

  useEffect(() => {
    const handleScreenCanGoSleep = async (message) => {
      if (!isSupported) {
        console.log('WakeLock is not supported');
        return;
      }
      if (message === 0) {
        await enable();
      } else {
        await disable();
      }
    };

    addEventListener('SetScreenCanGoSleep', handleScreenCanGoSleep);
    return () =>
      removeEventListener('SetScreenCanGoSleep', handleScreenCanGoSleep);
  }, [addEventListener, removeEventListener, enable, disable, isSupported]);

  return null;
};

export default SDK_SetScreenCanGoSleep;
